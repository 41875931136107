import { useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { format, parseISO, addDays } from "date-fns";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import MinusRoundIcon from "@rsuite/icons/MinusRound";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import { IconButton, Button, InputNumber, Form, ButtonToolbar, ButtonGroup, Tabs, Text, Input, Grid, Row, Col } from "rsuite";
import API from "../../../utils/API";

function SingleConcreteLabForm(props) {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const is_add_mode = !props.id;
  const workorder_id = props.workorder_id;
  const copy_last_id = searchParams.get("cp");

  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm();
  const watchSampleDate = watch("sample_date", undefined);

  const { fields: cylinders, remove: cylindersRemove, append: cylindersAppend } = useFieldArray({ control, name: "cylinder_specimens" });
  const { fields: cubes, remove: cubesRemove, append: cubesAppend } = useFieldArray({ control, name: "cube_specimens" });

  useEffect(() => {
    if (!is_add_mode) {
      API.get(`/concrete_samples/${props.id}`)
        .then((r) => {
          reset({
            id: r.data.id,
            supplier: r.data.supplier && r.data.supplier,
            plant: r.data.plant && r.data.plant,
            sheet_number: r.data.sheet_number && r.data.sheet_number,
            mix_code: r.data.mix_code && r.data.mix_code,
            design_mpa: r.data.design_mpa && r.data.design_mpa,
            aggregate_size: r.data.aggregate_size && r.data.aggregate_size,
            design_slump: r.data.design_slump && r.data.design_slump,
            truck_number: r.data.truck_number && r.data.truck_number,
            batch_date: r.data.batch_date && format(parseISO(r.data.batch_date), "y-MM-dd"),
            batch_time: r.data.batch_time && format(parseISO(r.data.batch_time.split("Z")[0]), "HH:mm"),
            sample_date: r.data.sample_date && format(parseISO(r.data.sample_date), "y-MM-dd"),
            sample_time: r.data.sample_time && format(parseISO(r.data.sample_time.split("Z")[0]), "HH:mm"),
            docket_number: r.data.docket_number && r.data.docket_number,
            load_size: r.data.load_size && r.data.load_size,
            progressive_total: r.data.progressive_total && r.data.progressive_total,
            initial_slump: r.data.initial_slump && r.data.initial_slump,
            final_slump: r.data.final_slump && r.data.final_slump,
            ambient_temperature: r.data.ambient_temperature && r.data.ambient_temperature,
            concrete_temperature: r.data.concrete_temperature && r.data.concrete_temperature,
            entrained_air: r.data.entrained_air && r.data.entrained_air,
            entrained_air_method: r.data.entrained_air_method && r.data.entrained_air_method,
            sampling_method: r.data.sampling_method && r.data.sampling_method,
            compaction_type: r.data.compaction_type && r.data.compaction_type,
            pour_location: r.data.pour_location && r.data.pour_location,
            sample_comments: r.data.sample_comments && r.data.sample_comments,
            cylinder_specimens: r.data.cylinder_specimens
              ? r.data.cylinder_specimens
                  .map((cs) => {
                    return {
                      ...cs,
                      test_date: cs.test_date ? format(parseISO(cs.test_date), "y-MM-dd") : null,
                    };
                  })
                  .sort((a, b) => a.days_requested > b.days_requested || a.specimen_id.localeCompare(b.specimen_id))
              : undefined,
            cube_specimens: r.data.cube_specimens
              ? r.data.cube_specimens
                  .map((cs) => {
                    return {
                      ...cs,
                      test_date: cs.test_date ? format(parseISO(cs.test_date), "y-MM-dd") : null,
                    };
                  })
                  .sort((a, b) => a.days_requested > b.days_requested || a.specimen_id.localeCompare(b.specimen_id))
              : undefined,
          });
        })
        .catch((e) => console.log(e));
    } else {
      if (copy_last_id) {
        API.get(`/concrete_samples/${copy_last_id}`)
          .then((r) => {
            reset({
              workorder_id: workorder_id,
              supplier: r.data.supplier && r.data.supplier,
              plant: r.data.plant && r.data.plant,
              mix_code: r.data.mix_code && r.data.mix_code,
              design_mpa: r.data.design_mpa && r.data.design_mpa,
              aggregate_size: r.data.aggregate_size && r.data.aggregate_size,
              design_slump: r.data.design_slump && r.data.design_slump,
              batch_date: r.data.batch_date && format(parseISO(r.data.batch_date), "y-MM-dd"),
              sample_date: r.data.sample_date && format(parseISO(r.data.sample_date), "y-MM-dd"),
              sampling_method: r.data.sampling_method && r.data.sampling_method,
              compaction_type: r.data.compaction_type && r.data.compaction_type,
              pour_location: r.data.pour_location && r.data.pour_location,
              sample_comments: r.data.sample_comments && r.data.sample_comments,
              cylinder_specimens: r.data.cylinder_specimens
                ? r.data.cylinder_specimens
                    .map((cs) => ({
                      ...cs,
                      mould_number: undefined,
                      test_time: undefined,
                      test_date: format(parseISO(cs.test_date), "y-MM-dd"),
                    }))
                    .sort((a, b) => a.days_requested > b.days_requested || a.specimen_id.localeCompare(b.specimen_id))
                : undefined,
              cube_specimens: r.data.cube_specimens
                ? r.data.cube_specimens
                    .map((cs) => ({
                      ...cs,
                      mould_number: undefined,
                      test_time: undefined,
                      test_date: format(parseISO(cs.test_date), "y-MM-dd"),
                    }))
                    .sort((a, b) => a.days_requested > b.days_requested || a.specimen_id.localeCompare(b.specimen_id))
                : undefined,
            });
          })
          .catch((e) => console.log(e));
      } else {
        reset({
          workorder_id: workorder_id,
        });
      }
    }
  }, [props.id, reset, is_add_mode, copy_last_id, workorder_id]);

  const onSubmit = (data) => {
    console.log(errors);
    if (data.batch_date === "") {
      data.batch_date = null;
    }
    if (data.batch_time === "") {
      data.batch_time = null;
    }
    if (data.sample_date === "") {
      data.sample_date = null;
    }
    if (data.sample_time === "") {
      data.sample_time = null;
    }

    // Map over cylinders to set any undefineds to null as backend expects this
    data.cylinder_specimens?.forEach((item, i) => {
      if (item.test_date === "") {
        data.cylinder_specimens[i].test_date = null;
      } else {
        data.cylinder_specimens[i].test_date = format(addDays(data.batch_date ? new Date(data.batch_date) : new Date(), data.cylinder_specimens[i].days_requested), "yyyy-MM-dd");
      }
      if (item.test_time === "") {
        data.cylinder_specimens[i].test_time = null;
      }
    });

    // Map over cubes to set any undefineds to null as backend expects this

    data.cube_specimens?.forEach((item, i) => {
      if (item.test_date === "") {
        data.cube_specimens[i].test_date = null;
      } else {
        data.cube_specimens[i].test_date = format(addDays(data.batch_date ? new Date(data.batch_date) : new Date(), data.cube_specimens[i].days_requested), "yyyy-MM-dd");
      }
      if (item.test_time === "") {
        data.cube_specimens[i].test_time = null;
      }
    });

    if (!is_add_mode) {
      API.patch(`/concrete_samples/${props.id}`, data)
        .then((r) => navigate(`/workorders/${workorder_id}`))
        .catch((e) => console.log(e.response.data.errors));
    } else {
      console.log("Creating");
      API.post("/concrete_samples", data)
        .then((r) => navigate(`/workorders/${workorder_id}`))
        .catch((e) => console.log(e.response.data.errors));
    }
  };

  const handleDaysRequestedChange = (value, calcTarget) => {
    // if there is a sample date we should calculate the crush / test date
    if (watchSampleDate) {
      // setValue(calcTarget, format(addDays(Date.parse(watchSampleDate), value), "y-MM-dd"));
      setValue(calcTarget, format(addDays(parseISO(watchSampleDate), Number(value)), "y-MM-dd"));

      // console.log("Parsed Date:", addDays(parseISO(watchSampleDate), Number(value)))
    }
  };

  const nextString = (str) => {
    if (!str) return "A"; // return 'A' if str is empty or null

    let tail = "";
    let i = str.length - 1;
    let char = str[i];
    // find the index of the first character from the right that is not a 'Z'
    while (char === "Z" && i > 0) {
      i--;
      char = str[i];
      tail = "A" + tail; // tail contains a string of 'A'
    }
    if (char === "Z")
      // the string was made only of 'Z'
      return "AA" + tail;
    // increment the character that was not a 'Z'
    return str.slice(0, i) + String.fromCharCode(char.charCodeAt(0) + 1) + tail;
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid fluid>
        <input type="hidden" {...register("id", { valueAsNumber: true })} />
        <input type="hidden" {...register("workorder_id", { valueAsNumber: true })} />
        <Row>
          <Col xs={4}>
            <Text size="sm">Supplier</Text>
            <Controller
              name="supplier"
              control={control}
              render={({ field }) => <Input size="md" id={field.name} value={field.value} onChange={(value) => field.onChange(value === "" || undefined ? null : value)} />}
            />
          </Col>

          <Col xs={4}>
            <Text size="sm">Plant</Text>
            <Controller
              name="plant"
              control={control}
              render={({ field }) => <Input size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
            />
          </Col>
          <Col xs={4}>
            <Text size="sm">Sheet</Text>
            <Controller
              name="sheet_number"
              control={control}
              render={({ field }) => <Input size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <Text size="sm">Mix code</Text>
            <Controller
              name="mix_code"
              control={control}
              render={({ field }) => <Input size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
            />
          </Col>
          <Col xs={4}>
            <Text size="sm">Design MPa</Text>
            <Controller
              name="design_mpa"
              control={control}
              render={({ field }) => <InputNumber size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
            />
          </Col>

          <Col xs={4}>
            <Text size="sm">Agg Size</Text>
            <Controller
              name="aggregate_size"
              control={control}
              render={({ field }) => <InputNumber size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
            />
          </Col>
          <Col xs={4}>
            <Text size="sm">Design Slump</Text>
            <Controller
              name="design_slump"
              control={control}
              render={({ field }) => <InputNumber size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <Text size="sm">Truck</Text>
            <Controller
              name="truck_number"
              control={control}
              render={({ field }) => <Input size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
            />
          </Col>
          <Col xs={4}>
            <Text size="sm">Docket No</Text>
            <Controller
              name="docket_number"
              control={control}
              render={({ field }) => (
                <Input size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} placeholder="Docket" />
              )}
            />
          </Col>
          <Col xs={4}>
            <Text size="sm">Load</Text>
            <Controller
              name="load_size"
              control={control}
              render={({ field }) => <Input size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
            />
          </Col>
          <Col xs={4}>
            <Text size="sm">Prog Total</Text>
            <Controller
              name="progressive_total"
              control={control}
              render={({ field }) => <Input size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <Text size="sm">Batch Date</Text>
            <Controller
              name="batch_date"
              control={control}
              render={({ field }) => (
                <Input
                  size="md"
                  type="date"
                  min="2000-01-01"
                  max="2050-01-01"
                  // defaultValue={format(new Date(), "y-MM-dd")}
                  id={field.name}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  placeholder="Batch Date"
                />
              )}
            />
          </Col>
          <Col xs={4}>
            <Text size="sm">Time</Text>
            <Controller
              name="batch_time"
              control={control}
              render={({ field }) => <Input size="md" type="time" id={field.name} value={field.value} onChange={(value) => field.onChange(value)} />}
            />
          </Col>
          <Col xs={4}>
            <Text size="sm">Sample Date</Text>
            <Controller
              name="sample_date"
              control={control}
              render={({ field }) => (
                <Input
                  size="md"
                  type="date"
                  min="2000-01-01"
                  max="2050-01-01"
                  // defaultValue={format(new Date(), "y-MM-dd")}
                  id={field.name}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  placeholder="Batch Date"
                />
              )}
            />
          </Col>
          <Col xs={4}>
            <Text size="sm">Sample Time</Text>
            <Controller
              name="sample_time"
              control={control}
              render={({ field }) => <Input size="md" type="time" id={field.name} value={field.value} onChange={(value) => field.onChange(value)} />}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <Text size="sm">Initial Slump</Text>
            <Controller
              name="initial_slump"
              control={control}
              render={({ field }) => <InputNumber size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : parseInt(v))} />}
            />
          </Col>
          <Col xs={4}>
            <Text size="sm">Final Slump</Text>
            <Controller
              name="final_slump"
              control={control}
              render={({ field }) => <InputNumber size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : parseInt(v))} />}
            />
          </Col>
          <Col xs={4}>
            <Text size="sm">Amb Temp</Text>
            <Controller
              name="ambient_temperature"
              control={control}
              render={({ field }) => <InputNumber size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : parseInt(v))} />}
            />
          </Col>
          <Col xs={4}>
            <Text size="sm">Conc Temp</Text>
            <Controller
              name="concrete_temperature"
              control={control}
              render={({ field }) => <InputNumber size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : parseInt(v))} />}
            />
          </Col>
          <Col xs={4}>
            <Text size="sm">Air %</Text>
            <Controller
              name="entrained_air"
              control={control}
              render={({ field }) => <Input size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={8}>
            <Text size="sm">Pour Location</Text>
            <Controller
              name="pour_location"
              control={control}
              render={({ field }) => (
                <Input size="md" as="textarea" rows={2} id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />
              )}
            />
          </Col>
          <Col xs={8}>
            <Text size="sm">Sample comments</Text>
            <Controller
              name="sample_comments"
              control={control}
              render={({ field }) => (
                <Input size="md" as="textarea" rows={2} id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />
              )}
            />
          </Col>
        </Row>
      </Grid>

      <Tabs defaultActiveKey="1" appearance="subtle">
        <Tabs.Tab eventKey="1" title="Cylinders" key={1}>
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                onClick={() => {
                  cylindersAppend({
                    specimen_id: nextString(cylinders[cylinders.length - 1]?.specimen_id ?? ""),
                    mould_number: "",
                    days_requested: undefined,
                    curing_type: "M",
                    cap_type: "S",
                    test_date: undefined,
                    test_time: undefined,
                  });
                }}
                appearance="subtle"
                style={{ borderRadius: 0 }}
                startIcon={<AddOutlineIcon />}
              >
                Add Row
              </Button>
              <Button
                disabled={cylinders.length === 0}
                onClick={() => {
                  cylindersRemove(cylinders.length && cylinders.length - 1);
                }}
                appearance="subtle"
                style={{ borderRadius: 0 }}
                startIcon={<MinusRoundIcon />}
              >
                Remove Row
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
          {/* </Stack> */}
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Mould</th>
                <th>Days</th>
                <th>Test Date</th>
                <th>Curing</th>
                <th>Cap</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {cylinders &&
                cylinders.map((item, i) => {
                  const fieldName = `cylinder_specimens[${i}]`;
                  return (
                    <tr key={`${fieldName}`} className="table-row-form">
                      <td>
                        <Controller
                          name={`${fieldName}.specimen_id`}
                          control={control}
                          render={({ field }) => <Input size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
                        />
                      </td>
                      <td>
                        <Controller
                          name={`${fieldName}.mould_number`}
                          control={control}
                          render={({ field }) => <Input size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
                        />
                      </td>
                      <td>
                        <Controller
                          name={`${fieldName}.days_requested`}
                          control={control}
                          // onChange={(e) => handleDaysRequestedChange(e, `cylinder_specimens.[${i}].test_date`)}
                          render={({ field }) => (
                            <InputNumber
                              size="md"
                              id={field.name}
                              value={field.value}
                              onChange={(e) => {
                                handleDaysRequestedChange(e, `cylinder_specimens.[${i}].test_date`);
                                setValue(field.name, e === "" ? null : parseFloat(e));
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>{watch(`cylinder_specimens.[${i}].test_date`)}</td>
                      <td>
                        <Controller
                          name={`${fieldName}.curing_type`}
                          control={control}
                          render={({ field }) => <Input size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
                        />
                      </td>
                      <td>
                        <Controller
                          name={`${fieldName}.cap_type`}
                          control={control}
                          render={({ field }) => <Input size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
                        />
                      </td>

                      <td>
                        <Input type="hidden" name={`${fieldName}.test_date`} {...register(`cylinder_specimens.${i}.test_date`)} />
                        <Input type="hidden" name={`${fieldName}.test_time`} {...register(`cylinder_specimens.${i}.test_time`)} />

                        <button type="button" onClick={() => cylindersRemove(i)} tabIndex={-1}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Tabs.Tab>
        <Tabs.Tab eventKey="2" title="Cubes" key={2}>
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                onClick={() => {
                  cubesAppend({
                    specimen_id: nextString(cubes[cubes.length - 1]?.specimen_id ?? ""),
                    mould_number: "",
                    days_requested: undefined,
                    curing_type: "M",
                    cap_type: "S",
                    test_date: undefined,
                    test_time: undefined,
                  });
                }}
                appearance="subtle"
                style={{ borderRadius: 0 }}
                startIcon={<AddOutlineIcon />}
              >
                Add Row
              </Button>
              <Button
                disabled={cubes.length === 0}
                onClick={() => {
                  cubesRemove(cubes.length && cubes.length - 1);
                }}
                appearance="subtle"
                style={{ borderRadius: 0 }}
                startIcon={<MinusRoundIcon />}
              >
                Remove Row
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
          {/* </Stack> */}
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Mould</th>
                <th>Days</th>
                <th>Test Date</th>
                <th>Curing</th>
                <th>Nominal Size</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {cubes &&
                cubes.map((item, i) => {
                  const fieldName = `cube_specimens[${i}]`;
                  return (
                    <tr key={`${fieldName}`} className="table-row-form">
                      <td>
                        <Controller
                          name={`${fieldName}.specimen_id`}
                          control={control}
                          render={({ field }) => <Input size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
                        />
                      </td>
                      <td>
                        <Controller
                          name={`${fieldName}.mould_number`}
                          control={control}
                          render={({ field }) => <Input size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
                        />
                      </td>
                      <td>
                        <Controller
                          name={`${fieldName}.days_requested`}
                          control={control}
                          render={({ field }) => (
                            <InputNumber
                              size="md"
                              id={field.name}
                              value={field.value}
                              onChange={(e) => {
                                handleDaysRequestedChange(e, `cube_specimens.[${i}].test_date`);
                                setValue(field.name, e === "" ? null : parseFloat(e));
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>{watch(`cube_specimens.[${i}].test_date`)}</td>
                      <td>
                        <Controller
                          name={`${fieldName}.curing_type`}
                          control={control}
                          render={({ field }) => <Input size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
                        />
                      </td>
                      <td>
                        <Controller
                          name={`${fieldName}.nominal_size`}
                          control={control}
                          render={({ field }) => <Input size="md" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
                        />
                      </td>

                      <td>
                        <Input type="hidden" name={`${fieldName}.test_date`} {...register(`cube_specimens.${i}.test_date`)} />
                        <Input type="hidden" name={`${fieldName}.test_time`} {...register(`cube_specimens.${i}.test_time`)} />

                        <button type="button" onClick={() => cubesRemove(i)} tabIndex={-1}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Tabs.Tab>
        <Tabs.Tab eventKey="3" title="Cores" key={3}></Tabs.Tab>
      </Tabs>
      <br />
      <IconButton type="submit" appearance="primary" color="green" disabled={isSubmitting} icon={is_add_mode ? <AddOutlineIcon /> : <CheckRoundIcon />}>
        {is_add_mode ? "Add Concrete Sample" : "Save Changes"}
      </IconButton>
    </Form>
  );
}

export default SingleConcreteLabForm;
