import { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import ConcreteSampleList from "../../../components/ConcreteSampleList";
import API from "../../../utils/API";

function LatestConcreteTestSamples() {
  const [concrete_test_samples, set_concrete_test_samples] = useState();
  const [concrete_test_samples_count, set_concrete_test_samples_count] = useState(null);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(0);

  const getConcreteTestSamples = useCallback(() => {
    API.get("/concrete_samples", { params: { page: pageNumber, limit: 10 } })
      .then((r) => {
        set_concrete_test_samples(r.data.data);
        setNumPages(r.data.pages);
        set_concrete_test_samples_count(r.data.results);
      })
      .catch((e) => console.log(e));
  }, [pageNumber]);

  useEffect(() => {
    getConcreteTestSamples();
  }, [getConcreteTestSamples]);

  return (
    <div className="page-container">
      <div className="page-header">
        <div className="page-header-container">
          <div className="ph-left">
            <h3>{concrete_test_samples_count && concrete_test_samples_count} concrete_test_samples</h3>
          </div>
        </div>
      </div>

      <div className="column">
        <div className="container ">
          <ConcreteSampleList concrete_samples={concrete_test_samples} />
          <div className="pagination-row">
            <span className={pageNumber == 0 ? "pg-disabled" : undefined} onClick={pageNumber == 0 ? undefined : () => setPageNumber(pageNumber - 1)}>
              <MdNavigateBefore size={18} /> Previous Page
            </span>
            <span>
              Page {pageNumber} of {numPages}
            </span>
            <span className={pageNumber == numPages ? "pg-disabled" : undefined} onClick={pageNumber == numPages ? undefined : () => setPageNumber(pageNumber + 1)}>
              Next Page <MdNavigateNext size={18} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LatestConcreteTestSamples;
