import { useEffect, useState, useCallback, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import CheckRoundIcon from "@rsuite/icons/CheckRound";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import { Form, IconButton, Grid, Row, Col, Text, Input, SelectPicker } from "rsuite";
import { AccountConsumer } from "../../context/AccountProvider";

import API from "../../utils/API";

function FormClient(props) {
  let navigate = useNavigate();
  const is_add_mode = !props.id;
  const acc = useContext(AccountConsumer);

  const [price_lists, set_price_lists] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  const getPriceLists = useCallback(() => {
    // console.log(id);
    API.get(`/price_lists`)
      .then((r) => {
        set_price_lists((r.data.data = r.data.data.map(({ id: value, name: label }) => ({ value, label }))));
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    getPriceLists();
    if (!is_add_mode) {
      API.get(`/clients/${props.id}`)
        .then((r) => {
          reset({
            id: r.data.id,
            name: r.data.name,
            abn: r.data.abn,
            address_line_1: r.data.locations && r.data.locations[0].address_line_1,
            address_line_2: r.data.locations && r.data.locations[0].address_line_2,
            price_list_id: r.data?.price_list?.id,
          });
        })
        .catch((e) => console.log(e));
    }
  }, [props.id, reset, is_add_mode, getPriceLists]);

  const onSubmit = (data) => {
    console.log(data);
    if (!is_add_mode) {
      API.patch(`/clients/${props.id}`, data)
        .then((r) => navigate(`/clients`))
        .catch((e) => console.log(e.response.data.errors));
    } else {
      API.post("/clients", data)
        .then((r) => navigate(`/clients`))
        .catch((e) => console.log(e.response.data.errors));
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid style={{ padding: 0, margin: 0 }}>
        <Row>
          <Col xs={24}>
            <input type="hidden" {...register("id", { valueAsNumber: true })} />

            <Text>Name</Text>
            <Controller
              name="name"
              control={control}
              render={({ field }) => <Input size="md" id={field.name} value={field.value ?? ""} onChange={(value) => field.onChange(value === "" || undefined ? null : value)} />}
            />
            {errors.name && <span>This field is required</span>}
          </Col>
        </Row>

        {acc.user_level >= 5 && (
          <Row>
            <Text>Attached Pricelist</Text>
            <Controller
              name="price_list_id"
              control={control}
              render={({ field }) => <SelectPicker {...field} loading={!price_lists} data={price_lists ?? []} label="Price List" />}
            />
            {errors.abn && <span>This field is required</span>}
          </Row>
        )}

        <Row>
          <Col xs={24} hidden>
            <Text>ABN</Text>
            <Controller
              name="abn"
              control={control}
              render={({ field }) => <Input size="md" id={field.name} value={field.value ?? ""} onChange={(value) => field.onChange(value === "" || undefined ? null : value)} />}
            />
            {errors.abn && <span>This field is required</span>}
          </Col>
        </Row>

        <Row>
          <Col xs={24} hidden>
            <Text>Street Address</Text>
            <Controller
              name="address_line_1"
              control={control}
              render={({ field }) => <Input size="md" id={field.name} value={field.value ?? ""} onChange={(value) => field.onChange(value === "" || undefined ? null : value)} />}
            />
            {errors.address_line_1 && <span>This field is required</span>}
          </Col>
        </Row>

        <Row>
          <Col xs={24} hidden>
            <Text>Suburb</Text>
            <Controller
              name="address_line_2"
              control={control}
              render={({ field }) => <Input size="md" id={field.name} value={field.value ?? ""} onChange={(value) => field.onChange(value === "" || undefined ? null : value)} />}
            />
            {errors.address_line_2 && <span>This field is required</span>}
          </Col>
        </Row>

        <Row>
          <br />
          <Col xs={24}>
            <IconButton type="submit" appearance="primary" color="green" disabled={isSubmitting} icon={is_add_mode ? <AddOutlineIcon /> : <CheckRoundIcon />}>
              {is_add_mode ? "Add Client" : "Save Changes"}
            </IconButton>
          </Col>
        </Row>
      </Grid>
    </Form>
  );
}

export default FormClient;
