import "rsuite/dist/rsuite.min.css";
// import "./css/app.scss";
import "./css/base.scss";
// import "rsuite/dist/rsuite-no-reset.min.css";

import { Routes, Route } from "react-router-dom";

// Pages

import Navigation from "./components/Navigation";

import Projects from "./pages/projects/Projects";
import ProjectForm from "./pages/projects/ProjectForm";
import Clients from "./pages/clients/Clients";
import ClientForm from "./pages/clients/ClientForm";
import Project from "./pages/projects/Project";
import Dashboard from "./pages/dashboard/index";
import Workorders from "./pages/workorders/Workorders";
import Workorder from "./pages/workorders/Workorder";
import WorkorderForm from "./pages/workorders/WorkorderForm";
import WorkordersBillingView from "./pages/workorders/WorkordersBilling";

import PriceLists from "./pages/misc/PriceLists";

import SingleConcreteLab from "./pages/tests/concrete/SingleConcreteLab";

import AccountProvider from "./context/AccountProvider";
import FormLogin from "./pages/auth/Login";
import ProjectContacts from "./pages/projects/contacts/ProjectContacts";
import ClientContacts from "./pages/clients/contacts/ClientContacts";
import Reports from "./pages/reports/Reports";
import Crushing from "./pages/tests/concrete/crushing/Crushing";
import Logout from "./pages/auth/Logout";
import Schedule from "./pages/schedule/Schedule";
import LatestConcreteTestSamples from "./pages/tests/concrete/LatestConcreteTestSamples";
import WorkorderDelete from "./pages/workorders/DelForm";
import { NotificationContextProvider } from "./context/NotificationContext";

import { Container, Header, Sidebar, Sidenav, Content, Nav, Breadcrumb, IconButton } from "rsuite";
import { useState } from "react";
import WorkorderInvoicingView from "./pages/workorders/WorkordersInvoicing";

function App() {
  return (
    <NotificationContextProvider>
      <AccountProvider>
        <Container>
          <Navigation />
          <Routes>
            <Route path="/" element={<Projects />} />

            <Route path="/login" element={<FormLogin />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/register" element={<span>register</span>} />

            <Route path="dashboard">
              <Route index={true} element={<Dashboard />} />
            </Route>

            <Route path="workorders">
              <Route index={true} element={<Workorders />} />
              <Route path=":workorderID" element={<Workorder />} />
              <Route path=":workorderID/update" element={<WorkorderForm />} />
              <Route path=":workorderID/delete" element={<WorkorderDelete />} />
              <Route path="new" element={<WorkorderForm />} />
              <Route path="billing" element={<WorkordersBillingView />} />
              <Route path="invoicing" element={<WorkorderInvoicingView />} />
            </Route>

            <Route path="projects">
              <Route index={true} element={<Projects />} />
              <Route path=":projectID" element={<Project />} />
              <Route path=":projectID/update" element={<ProjectForm />} />
              <Route path=":projectID/contacts" element={<ProjectContacts />} />
              <Route path="new" element={<ProjectForm />} />
            </Route>

            <Route path="clients">
              <Route index={true} element={<Clients />} />
              <Route path=":clientID" element={<ClientForm />} />
              <Route path=":clientID/update" element={<ClientForm />} />
              <Route path=":clientID/contacts" element={<ClientContacts />} />
              <Route path="new" element={<ClientForm />} />
            </Route>

            <Route path="lists">
              <Route index={true} element={<PriceLists />} />
            </Route>

            <Route path="reports">
              <Route index={true} element={<Reports />} />
            </Route>

            <Route path="schedule">
              <Route index={true} element={<Schedule />} />
            </Route>

            <Route path="tests">
              <Route path="concrete">
                <Route index={true} element={<h1>concrete tests menu</h1>} />

                <Route path="latest_concrete_samples">
                  <Route index={true} element={<LatestConcreteTestSamples />} />
                </Route>

                <Route path="single_lab">
                  <Route index={true} element={<SingleConcreteLab />} />
                  <Route path=":testID" element={<SingleConcreteLab />} />
                </Route>

                {/* Crushing for lab cylinders/cubes/cores */}
                <Route path="crushing">
                  <Route index={true} element={<Crushing />} />
                </Route>
              </Route>
            </Route>
          </Routes>
        </Container>
      </AccountProvider>
    </NotificationContextProvider>
  );
}

export default App;
