import { useEffect, useState, useCallback, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import { Form, IconButton, Grid, Row, Col, Text, Input, SelectPicker } from "rsuite";

import ReactSelect from "react-select";
import API from "../../utils/API";
import { AccountConsumer } from "../../context/AccountProvider";

function FormProject(props) {
  let navigate = useNavigate();
  const acc = useContext(AccountConsumer);
  const is_add_mode = !props.id;
  const [clients, set_clients] = useState();
  const [price_lists, set_price_lists] = useState([]);

  const get_clients = () => {
    // console.log(id);
    API.get(`/clients`, { params: { limit: 200 } })
      .then((r) => {
        let select_items = (r.data.data = r.data.data.map(({ id: value, name: label }) => ({ value, label })));
        set_clients(select_items);
      })
      .catch((e) => console.log(e));
  };

  const getPriceLists = useCallback(() => {
    // console.log(id);
    API.get(`/price_lists`)
      .then((r) => {
        set_price_lists((r.data.data = r.data.data.map(({ id: value, name: label }) => ({ value, label }))));
      })
      .catch((e) => console.log(e));
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  useEffect(() => {
    get_clients();
    getPriceLists();
    if (!is_add_mode) {
      API.get(`/projects/${props.id}`)
        .then((r) => {
          reset({
            id: r.data.id,
            name: r.data.name,
            billing_customer: r.data.billing_customer ? r.data.billing_customer.id : null,
            address_line_1: r.data.locations && r.data.locations[0].address_line_1,
            address_line_2: r.data.locations && r.data.locations[0].address_line_2,
            price_list_id: r.data?.price_list?.id,
          });
        })
        .catch((e) => console.log(e));
    }
  }, [props.id, reset, is_add_mode, getPriceLists]);

  const onSubmit = (data) => {
    if (!is_add_mode) {
      console.log("Updating");
      API.patch(`/projects/${props.id}`, data)
        .then((r) => navigate(`/projects/${props.id}`))
        .catch((e) => console.log(e.response.data.errors));
    } else {
      API.post("/projects", data)
        .then((r) => navigate(`/projects`))
        .catch((e) => console.log(e.response.data.errors));
    }
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid style={{ padding: 0, margin: 0 }}>
        <Row>
          <Col xs={24}>
            <input type="hidden" {...register("id", { valueAsNumber: true })} />

            <Text>Name</Text>
            <Controller
              name="name"
              control={control}
              render={({ field }) => <Input size="md" id={field.name} value={field.value} onChange={(value) => field.onChange(value === "" || undefined ? null : value)} />}
            />
            {errors.name && <span>This field is required</span>}
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Text>Billing customer</Text>
            <Controller
              name="billing_customer"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectPicker
                  loading={!clients}
                  disabled={!clients}
                  data={clients ? clients : [{ value: null, label: "Select" }]}
                  value={value}
                  onChange={(value) => onChange(value ? value : null)}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Text>Street Address</Text>
            <Controller
              name="address_line_1"
              control={control}
              render={({ field }) => <Input size="md" id={field.name} value={field.value} onChange={(value) => field.onChange(value === "" || undefined ? null : value)} />}
            />

            {errors.address_line_1 && <span>This field is required</span>}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Text>Suburb</Text>
            <Controller
              name="address_line_2"
              control={control}
              render={({ field }) => <Input size="md" id={field.name} value={field.value} onChange={(value) => field.onChange(value === "" || undefined ? null : value)} />}
            />

            {errors.address_line_2 && <span>This field is required</span>}
          </Col>
        </Row>

        {acc.user_level >= 5 && (
          <Row>
            <Text>Attached Pricelist</Text>
            <Controller
              name="price_list_id"
              control={control}
              render={({ field }) => <SelectPicker {...field} loading={!price_lists} disabled={!price_lists} data={price_lists ?? []} label="Price List" />}
            />
            {errors.price_list_id && <span>This field is required</span>}
          </Row>
        )}

        <Row>
          <br />
          <Col xs={24}>
            <IconButton type="submit" appearance="primary" color="green" disabled={isSubmitting} icon={is_add_mode ? <AddOutlineIcon /> : <CheckRoundIcon />}>
              {is_add_mode ? "Add Project" : "Save Changes"}
            </IconButton>
          </Col>
        </Row>
      </Grid>
    </Form>
  );
}

// const customStyles = {
//   option: (provided, state) => ({
//     ...provided,
//     // border: "1px dotted pink",
//     // color: state.isSelected ? "red" : "blue",
//     // padding: 10,
//   }),
//   control: () => ({
//     // none of react-select's styles are passed to <Control />
//     // width: 100,
//   }),
//   singleValue: (provided, state) => {
//     const opacity = state.isDisabled ? 0.5 : 1;
//     const transition = "opacity 300ms";

//     return { ...provided, opacity, transition };
//   },
// };

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "1rem",
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "1px",
    borderColor: "rgb(118, 118, 118);",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: "0",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: "0",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};

export default FormProject;
