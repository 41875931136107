import { Link } from "react-router-dom";
import { useContext } from "react";
import { HStack, Table, Text } from "rsuite";
import { AccountConsumer } from "../context/AccountProvider";

function ClientList(props) {
  const { clients, is_loading } = props;
  const acc = useContext(AccountConsumer);

  const { Column, HeaderCell, Cell } = Table;
  const data = clients;

  return (
    <Table
      loading={is_loading}
      height={300}
      hover={true}
      // fillHeight={true}
      showHeader={true}
      autoHeight={true}
      data={data ? data : []}
      bordered={true}
      cellBordered={true}
      headerHeight={true ? 30 : 40}
      rowHeight={true ? 30 : 46}
      style={{ fontSize: "0.8rem" }}
    >
      <Column>
        <HeaderCell style={{ padding: 4 }}>#</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="id" />
      </Column>

      <Column flexGrow>
        <HeaderCell style={{ padding: 4 }}>Name</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="name" />
      </Column>

      {acc.user_level >= 5 && (
        <Column width={200}>
          <HeaderCell style={{ padding: 4 }}>Attached Price List</HeaderCell>
          <Cell style={{ padding: 4, textTransform: "uppercase" }} dataKey="price_list">
            {(rowData, rowIndex) => {
              return rowData?.price_list?.name;
            }}
          </Cell>
        </Column>
      )}

      <Column>
        <HeaderCell style={{ padding: 4 }}>Contacts</HeaderCell>
        <Cell style={{ padding: 4 }}>
          {(rowData, rowIndex) => {
            return (
              <HStack>
                <Link to={`${rowData.id}/update`}>Edit</Link>
                <Link to={`${rowData.id}/contacts`}>Contacts</Link>
              </HStack>
            );
          }}
        </Cell>
      </Column>
    </Table>
  );
}

export default ClientList;
