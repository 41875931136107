import { Link } from "react-router-dom";
import { format, parseISO, formatDistanceToNow } from "date-fns";
import { Table, Text, IconButton } from "rsuite";

import CollaspedOutlineIcon from "@rsuite/icons/CollaspedOutline";
import ExpandOutlineIcon from "@rsuite/icons/ExpandOutline";
import CheckOutlineIcon from "@rsuite/icons/CheckOutline";
import { useState } from "react";
import InlineBillingForm from "../pages/workorders/InlineBillingForm";

function WorkorderList(props) {
  const { workorders, is_loading, products } = props;
  const [expandedRowKeys, setExpandedRowKeys] = useState(props.de);
  const { Column, HeaderCell, Cell } = Table;
  const rowKey = "id";

  const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
    <Cell {...props} style={{ padding: 4, fontWeight: "bold" }}>
      <IconButton
        size="xs"
        appearance="subtle"
        onClick={() => {
          onChange(rowData);
        }}
        icon={expandedRowKeys && expandedRowKeys.some((key) => key === rowData[rowKey]) ? <CollaspedOutlineIcon /> : <ExpandOutlineIcon />}
      />
      {rowData.id}
    </Cell>
  );

  const renderRowExpanded = (rowData) => {
    return (
      <InlineBillingForm
        getWorkorders={props.getWorkorders}
        products={products}
        line_items={rowData.line_items ? rowData.line_items : undefined}
        id={rowData.id}
        billing_customer_ref={rowData.billing_customer_ref}
        billing_internal_ref={rowData.billing_internal_ref}
      />
    );
  };

  const handleExpanded = (rowData) => {
    let open = false;
    const nextExpandedRowKeys = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }
    setExpandedRowKeys(nextExpandedRowKeys);
  };

  const getRowClassName = (rowData) => {
    if (!rowData || !rowData[rowKey]) {
      return "";
    }
    return expandedRowKeys?.includes(rowData[rowKey]) ? "unbilled" : "billed";
  };

  return (
    <Table
      loading={is_loading}
      height={300}
      hover={true}
      showHeader={true}
      autoHeight={true}
      data={workorders}
      bordered={true}
      cellBordered={true}
      headerHeight={30}
      rowHeight={30}
      style={{ fontSize: "0.8rem" }}
      rowKey={rowKey}
      expandedRowKeys={expandedRowKeys}
      rowClassName={getRowClassName}
      rowExpandedHeight={225}
      renderRowExpanded={renderRowExpanded}
    >
      <Column width={75}>
        <HeaderCell style={{ padding: 4 }}>ID</HeaderCell>
        <ExpandCell style={{ padding: 0 }} dataKey="id" expandedRowKeys={expandedRowKeys} onChange={handleExpanded} />
      </Column>

      <Column width={125}>
        <HeaderCell style={{ padding: 4 }}>Date</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="work_date">
          {(rowData) => {
            return rowData.work_date && format(parseISO(rowData.work_date), "EEE, dd/M/y");
          }}
        </Cell>
      </Column>

      <Column width={50}>
        <HeaderCell style={{ padding: 4 }}>Onsite</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="work_start_time">
          {(rowData) => {
            return rowData.work_start_time && rowData.work_start_time.split("T")[1].substring(0, 5);
          }}
        </Cell>
      </Column>

      <Column width={50}>
        <HeaderCell style={{ padding: 4 }}>Offsite</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="work_finish_time">
          {(rowData) => {
            return rowData.work_finish_time && rowData.work_finish_time.split("T")[1].substring(0, 5);
          }}
        </Cell>
      </Column>

      <Column width={35}>
        <HeaderCell style={{ padding: 4 }}>Field</HeaderCell>
        <Cell style={{ padding: 2 }} dataKey="field_complete">
          {(rowData) => {
            return rowData.field_complete && <CheckOutlineIcon style={{ fontSize: "1rem" }} color="green" />;
          }}
        </Cell>
      </Column>

      <Column width={200}>
        <HeaderCell style={{ padding: 4 }}>Customer / Project </HeaderCell>
        <Cell style={{ padding: 4, textTransform: "uppercase" }}>
          {(rowData) => {
            return rowData.project ? <Link to={`/projects/${rowData.project.id}`}>{rowData.project.name}</Link> : rowData.field_customer;
          }}
        </Cell>
      </Column>

      <Column width={200}>
        <HeaderCell style={{ padding: 4 }}>Location</HeaderCell>
        <Cell style={{ padding: 4, textTransform: "uppercase" }} dataKey="project">
          {(rowData) => {
            return rowData.project ? rowData.project.locations[0].address_line_1 : rowData.field_address_line_1;
          }}
        </Cell>
      </Column>

      <Column flexGrow={1}>
        <HeaderCell style={{ padding: 4 }}>Bill to</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="project">
          {(rowData) => {
            return rowData.project ? rowData.project.billing_customer.name : rowData.billing_customer ? rowData.billing_customer.name : null;
          }}
        </Cell>
      </Column>

      <Column width={80}>
        <HeaderCell style={{ padding: 4 }}>Created By</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="project">
          {(rowData) => {
            return rowData.created_by && rowData.created_by.first_name;
          }}
        </Cell>
      </Column>

      <Column flexGrow={2}>
        <HeaderCell style={{ padding: 4 }}></HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="project">
          {(rowData) => {
            return rowData.created_by && <Text muted>{formatDistanceToNow(rowData.created_date) + " ago"}</Text>;
          }}
        </Cell>
      </Column>

      <Column width={100}>
        <HeaderCell style={{ padding: 4 }}>...</HeaderCell>
        <Cell style={{ padding: 4 }}>
          {(rowData) => {
            return <Link to={`/workorders/${rowData.id}`}>View</Link>;
          }}
        </Cell>
      </Column>
    </Table>
  );
}

export default WorkorderList;
