import React, { createContext, useState } from "react";
import { Notification, useToaster, ButtonToolbar, SelectPicker, Button, Message } from "rsuite";

const NotificationContext = createContext();
export const NotificationConsumer = NotificationContext;

export const NotificationContextProvider = ({ children }) => {
  const [type, setType] = React.useState("info");
  const [placement, setPlacement] = React.useState("topEnd");
  const [duration, setDuration] = useState(5000);
  const toaster = useToaster();

  const message = (text, infoType = "info") => {
    return (
      <Message showIcon type={infoType ? infoType : "info"}>
        <strong>{type}!</strong> {text}.
      </Message>
    );
  };

  const notify = (text, infoType) => toaster.push(message(text, infoType), { placement, duration });

  const contextData = {
    notify,
  };

  return <NotificationContext.Provider value={contextData}>{children}</NotificationContext.Provider>;
};
