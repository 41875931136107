import Form from "./Form";
import { useParams } from "react-router-dom";
import { Container, Content, Panel, Heading } from "rsuite";

function WorkorderForm() {
  let { workorderID } = useParams();
  return (
    <Container>
      <Content>
        <Panel>
          <Heading level={6}>{workorderID ? "Update workorder" : "Add workorder"}</Heading>
        </Panel>

        <Panel>
          <Form id={workorderID} />
        </Panel>
      </Content>
    </Container>
  );
}

export default WorkorderForm;
