import { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import API from "../../utils/API";

import CheckRoundIcon from "@rsuite/icons/CheckRound";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import { IconButton, Form } from "rsuite";
import { FlexboxGrid, Button, ButtonToolbar, InputNumber, InputPicker, Input } from "rsuite";
import { useNavigate } from "react-router-dom";

function InlineBillingForm(props) {
  // console.log(line_item_data)
  let navigate = useNavigate();
  const products = props.products;
  // let line_items = props.line_items;
  const is_add_mode = !props.line_items;
  const workorder_id = props.id;
  const {
    register,
    watch,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    name: "workorder_line_items",
    control,
  });

  useEffect(() => {
    reset({
      workorder_id: workorder_id,
      workorder_line_items: props.line_items ?? [{ id: null, product_id: 1, units: null, discount: null }],
      billing_customer_ref: props.billing_customer_ref ?? undefined,
      billing_internal_ref: props.billing_internal_ref ?? undefined,
    });
  }, [props.id, reset, is_add_mode, props.line_items, workorder_id, props.billing_customer_ref, props.billing_internal_ref]);

  const onSubmit = (data) => {
    data.billing_customer_ref = data.billing_customer_ref === "" ? undefined : data.billing_customer_ref;
    data.billing_internal_ref = data.billing_internal_ref === "" ? undefined : data.billing_internal_ref;

    // data.workorder_line_items.map((item, i) => {
    //   console.log(item);
    //   item.id = (item.id === "") | undefined ? null : parseInt(item.id);
    //   item.units = (item.units === "") | undefined ? null : parseFloat(item.units);
    //   item.discount = item.discount === "" || undefined ? null : parseFloat(item.discount);
    // });

    console.log("line item saved data", data);
    API.patch(`/workorders/${workorder_id}/billing/`, data)
      .then((r) => props.getWorkorders())
      .catch((e) => console.log(e.response.data.errors));

    // props.line_items.push([...data.workorder_line_items]);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <input type="hidden" {...register("client_id", { valueAsNumber: true })} /> */}

      <FlexboxGrid justify="space-between">
        <FlexboxGrid.Item colspan={12}>
          Customer Reference
          <Controller
            name="billing_customer_ref"
            control={control}
            render={({ field }) => <Input id={field.name} value={field.value} onChange={(value) => field.onChange(value)} as="textarea" rows={2} />}
          />
          Internal notes
          <Controller
            name="billing_internal_ref"
            control={control}
            render={({ field }) => <Input id={field.name} value={field.value} onChange={(value) => field.onChange(value)} as="textarea" rows={2} />}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item
          colspan={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            paddingLeft: "1rem",
          }}
        >
          <table className="billing-inline-table">
            <thead>
              <tr>
                <th>Item</th>
                <th>qty</th>
                <th>disc %</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {fields.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Controller
                        name={`workorder_line_items[${i}].product_id`}
                        control={control}
                        rules={{ required: "Product is required" }}
                        render={({ field }) => (
                          <InputPicker
                            error={errors[field.name]?.message}
                            appearance="subtle"
                            style={{ width: 300, borderRadius: 0, borderColor: errors["workorder_line_items"] && errors["workorder_line_items"][i].product_id ? "red" : "" }}
                            size="sm"
                            id={field.name}
                            defaultValue={field.value}
                            data={products}
                            onChange={(e) => setValue(field.name, e)}
                          />
                        )}
                      />
                    </td>
                    <td>
                      {/*   */}
                      <Controller
                        name={`workorder_line_items[${i}].units`}
                        control={control}
                        rules={{ required: "qty is required" }}
                        render={({ field }) => (
                          <InputNumber
                            size="sm"
                            appearance="subtle"
                            style={{ width: 75, borderRadius: 0, borderColor: errors["workorder_line_items"] && errors["workorder_line_items"][i].units ? "red" : "" }}
                            id={field.name}
                            value={field.value}
                            // onChange={(e) => setValue(field.name, e === "" ? null : parseFloat(e))}
                            onChange={(e) => setValue(field.name, e)}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        name={`workorder_line_items[${i}].discount`}
                        control={control}
                        render={({ field }) => (
                          <InputNumber
                            size="sm"
                            appearance="subtle"
                            style={{ width: 75, borderRadius: 0 }}
                            id={field.name}
                            value={field.value}
                            onChange={(e) => setValue(field.name, e === "" ? null : parseFloat(e))}
                            // onChange={(e) => setValue(field.name, e)}
                          />
                        )}
                      />
                    </td>

                    <td>
                      <input type="hidden" name={`workorder_line_items[${i}].id`} {...register(`workorder_line_items.${i}.id`)} />
                      <IconButton color="red" size="sm" appearance="subtle" icon={<CloseOutlineIcon />} onClick={() => remove(i)} />
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan={1}>
                  <ButtonToolbar style={{ marginTop: "0.25rem" }}>
                    <Button
                      size="sm"
                      appearance="subtle"
                      block
                      startIcon={<AddOutlineIcon />}
                      onClick={() => {
                        append({ id: null, product_id: null, units: null, discount: null });
                      }}
                    >
                      Add Line item
                    </Button>
                  </ButtonToolbar>
                </td>

                <td colSpan={2}>
                  <ButtonToolbar style={{ marginTop: "0.25rem" }}>
                    <Button type="submit" size="sm" appearance="primary" color="green" block startIcon={<CheckRoundIcon />}>
                      Save
                    </Button>
                  </ButtonToolbar>
                </td>
              </tr>
            </tbody>
          </table>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </form>
  );
}

export default InlineBillingForm;
