import { Link } from "react-router-dom";
import { format, parseISO, formatDistanceToNow } from "date-fns";
import { Table, Text } from "rsuite";

function ConcreteSampleCylinderList(props) {
  const { concrete_samples, is_loading } = props;

  const { Column, HeaderCell, Cell, ColumnGroup } = Table;
  const data = concrete_samples;

  return (
    <Table
      loading={is_loading}
      height={200}
      minHeight={200}
      className="sample-table-rs"
      hover={true}
      // fillHeight={true}
      showHeader={true}
      autoHeight={true}
      data={data ? data : []}
      // bordered={true}
      cellBordered={true}
      headerHeight={60}
      rowHeight={25}
      // style={{ fontSize: "0.8rem" }}
    >
      <Column width={50}>
        <HeaderCell>#</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="id" />
      </Column>

      <Column width={80}>
        <HeaderCell>Supplier</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="supplier" />
      </Column>
      <Column>
        <HeaderCell>Plant</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="plant" />
      </Column>

      <ColumnGroup header="Mix Design">
        <Column width={100}>
          <HeaderCell>Code</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="mix_code" />
        </Column>
        <Column width={50}>
          <HeaderCell>MPa</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="design_mpa" />
        </Column>
        <Column width={50}>
          <HeaderCell>Agg</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="aggregate_size" />
        </Column>
        <Column width={65}>
          <HeaderCell>Slump</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="design_slump" />
        </Column>
      </ColumnGroup>

      <ColumnGroup header="Batch">
        <Column>
          <HeaderCell>Docket</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="docket_number" />
        </Column>

        <Column width={50}>
          <HeaderCell>Load</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="load_size" />
        </Column>
        <Column width={50}>
          <HeaderCell>Prog</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="progressive_total" />
        </Column>
        <Column width={80}>
          <HeaderCell>Truck</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="truck_number" />
        </Column>
        <Column width={50}>
          <HeaderCell>Date</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="batch_date">
            {(rowData) => rowData.batch_date && format(parseISO(rowData.batch_date), "dd/MM")}
          </Cell>
        </Column>
        <Column width={50}>
          <HeaderCell>Time</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="batch_time">
            {(rowData) => rowData.batch_time && rowData.batch_time.split("T")[1].substring(0, 5)}
          </Cell>
        </Column>
      </ColumnGroup>

      <ColumnGroup header="Sample">
        <Column width={50}>
          <HeaderCell>Date</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="sample_date">
            {(rowData) => rowData.sample_date && format(parseISO(rowData.sample_date), "dd/MM")}
          </Cell>
        </Column>
        <Column width={50}>
          <HeaderCell>Time</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="sample_time">
            {(rowData) => rowData.sample_time && rowData.sample_time.split("T")[1].substring(0, 5)}
          </Cell>
        </Column>
      </ColumnGroup>

      <ColumnGroup header="Slump">
        <Column width={50}>
          <HeaderCell>Int</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="initial_slump" />
        </Column>
        <Column width={50}>
          <HeaderCell>Final</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="final_slump" />
        </Column>
      </ColumnGroup>

      <ColumnGroup header="Temperature">
        <Column width={50}>
          <HeaderCell>Ambient</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="ambient_temperature" />
        </Column>
        <Column width={50}>
          <HeaderCell>Concrete</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="concrete_temperature" />
        </Column>
      </ColumnGroup>

      <Column width={50}>
        <HeaderCell>Air %</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="entrained_air" />
      </Column>
      <Column flexGrow={1}>
        <HeaderCell>Pour Location</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="pour_location" />
      </Column>
      <Column flexGrow={1}>
        <HeaderCell>Field Comments</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="sample_comments" />
      </Column>

      <Column width={50}>
        <HeaderCell></HeaderCell>
        <Cell style={{ padding: 4 }}>{(rowData, rowIndex) => <Link to={`/tests/concrete/single_lab/new?wo=${rowData.workorder_id}&csid=${rowData.id}`}>View</Link>}</Cell>
      </Column>
    </Table>
  );
}

export default ConcreteSampleCylinderList;
