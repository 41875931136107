import { Link } from "react-router-dom";
import { format, parseISO, formatDistanceToNow } from "date-fns";
import { Table, Text } from "rsuite";

function WorkorderList(props) {
  const { workorders, is_loading } = props;

  const { Column, HeaderCell, Cell } = Table;
  const data = workorders;

  return (
    <Table
      loading={is_loading}
      height={300}
      hover={true}
      // fillHeight={true}
      showHeader={true}
      autoHeight={true}
      data={data ? data : []}
      bordered={true}
      cellBordered={true}
      headerHeight={true ? 30 : 40}
      rowHeight={true ? 30 : 46}
      style={{ fontSize: "0.8rem" }}
    >
      <Column width={60}>
        <HeaderCell style={{ padding: 4 }}>ID</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="id" />
      </Column>

      <Column width={125}>
        <HeaderCell style={{ padding: 4 }}>Date</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="work_date">
          {(rowData, rowIndex) => {
            return rowData.work_date && format(parseISO(rowData.work_date), "EEE, dd/M/y");
          }}
        </Cell>
      </Column>

      <Column width={50}>
        <HeaderCell style={{ padding: 4 }}>Onsite</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="work_start_time">
          {(rowData, rowIndex) => {
            return rowData.work_start_time && rowData.work_start_time.split("T")[1].substring(0, 5);
          }}
        </Cell>
      </Column>

      <Column width={50}>
        <HeaderCell style={{ padding: 4 }}>Offsite</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="work_finish_time">
          {(rowData, rowIndex) => {
            return rowData.work_finish_time && rowData.work_finish_time.split("T")[1].substring(0, 5);
          }}
        </Cell>
      </Column>

      <Column width={200}>
        <HeaderCell style={{ padding: 4 }}>Customer / Project </HeaderCell>
        <Cell style={{ padding: 4, textTransform: "uppercase" }}>
          {(rowData, rowIndex) => {
            return rowData.project ? <Link to={`/projects/${rowData.project.id}`}>{rowData.project.name}</Link> : rowData.field_customer;
          }}
        </Cell>
      </Column>

      <Column width={200}>
        <HeaderCell style={{ padding: 4 }}>Location</HeaderCell>
        <Cell style={{ padding: 4, textTransform: "uppercase" }} dataKey="project">
          {(rowData, rowIndex) => {
            return rowData.project ? rowData.project.locations[0].address_line_2 : rowData.field_address_line_2;
          }}
        </Cell>
      </Column>

      <Column flexGrow={1}>
        <HeaderCell style={{ padding: 4 }}>Bill to</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="project">
          {(rowData, rowIndex) => {
            return rowData.project ? rowData.project.billing_customer.name : rowData.billing_customer ? rowData.billing_customer.name : null;
          }}
        </Cell>
      </Column>

      <Column width={80}>
        <HeaderCell style={{ padding: 4 }}>Created By</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="project">
          {(rowData, rowIndex) => {
            return rowData.created_by && rowData.created_by.first_name;
          }}
        </Cell>
      </Column>

      <Column flexGrow={2}>
        <HeaderCell style={{ padding: 4 }}></HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="project">
          {(rowData, rowIndex) => {
            return rowData.created_by && <Text muted>{formatDistanceToNow(rowData.created_date) + " ago"}</Text>;
          }}
        </Cell>
      </Column>

      <Column width={100}>
        <HeaderCell style={{ padding: 4 }}>...</HeaderCell>
        <Cell style={{ padding: 4 }}>
          {(rowData, rowIndex) => {
            return <Link to={`/workorders/${rowData.id}`}>View</Link>;
          }}
        </Cell>
      </Column>
    </Table>
  );
}

export default WorkorderList;
