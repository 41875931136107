import Form from "./Form";
import { useParams } from "react-router-dom";
import { Container, Content, Panel, Heading } from "rsuite";

function ProjectForm() {
  let { projectID } = useParams();
  return (
    <Container>
      <Content>
        <Panel>
          <Heading level={6}>{projectID ? "Update project" : "Add project"}</Heading>
        </Panel>

        <Panel>
          <Form id={projectID} />
        </Panel>
      </Content>
    </Container>
  );
}

export default ProjectForm;
