import FullCalendar from "@fullcalendar/react"; // must go before plugins
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { format, parseISO, subDays } from "date-fns";
import { useState } from "react";
import { useEffect } from "react";
import { Content, Container, DateRangePicker, Stack, Panel, IconButton, Button, ButtonToolbar, ButtonGroup, Heading, Text, SelectPicker } from "rsuite";
import API from "../../utils/API";

function Schedule() {
  const [users, setUsers] = useState();
  const [workorders, setWorkorders] = useState();

  const handleChangeDate = ({ startStr, endStr }) => {
    API.get(`/active_testers`)
      .then((r) => {
        let this_r = (r.data = r.data.map(({ id, first_name, last_name }) => ({ id, title: first_name + " " + last_name })));
        // this_r.push({ id: "UNASSIGNED", title: "Unassigned" });
        setUsers(this_r);
      })
      .catch((e) => console.log(e));
    API.get("/workorders/schedule/", { params: { daterange_from: startStr, daterange_to: endStr } })
      .then((r) => {
        let this_r = [];
        this_r =
          r.data &&
          r.data.map((wo) => {
            let this_datetime_start = wo.work_start_time ? new Date(`${wo.work_date.split("T")[0]}T${wo.work_start_time.split("T")[1]}`) : wo.work_date;
            let this_datetime_finish = wo.work_finish_time ? new Date(`${wo.work_finish.split("T")[0]}T${wo.work_finish_time.split("T")[1]}`) : wo.work_finish;
            console.log(wo);
            return {
              title: wo.project ? wo.project.name : wo.billing_customer,
              start: this_datetime_start,
              end: this_datetime_finish,
              resourceIds: [wo.created_by && wo.created_by.id],
              ...wo,
            };
          });
        setWorkorders(this_r);
      })
      .catch((e) => console.log(e));
  };

  return (
    <Container>
      <Content>
        {workorders &&
          workorders.map((event) => (
            <div className="sh-container" key={event.id}>
              <div className={event.field_complete ? "sh-body w-complete" : " sh-body w-active"}>
                {/* <div className="sh-body-header">
                      <span>{event.id}</span>
                      {event.field_complete ? <span className="number-bubble-purple">Complete</span> : <span className="number-bubble-green">Incomplete</span>}
                    </div> */}
                <div className="sh-middle">
                  {event.project && (
                    <div>
                      <span>{event.project.name}</span> <span>{event.project.locations && event.project.locations[0].address_line_2}</span>
                    </div>
                  )}

                  {!event.project && (
                    <div>
                      <span>{event.field_customer ? event.field_customer : event.field_address_line_1}</span> <span>{event.field_address_line_2}</span>
                    </div>
                  )}
                </div>
                <div className="sh-time">
                  {event.work_finish_time && format(parseISO(event.work_start_time.split("Z")[0]), "HH:mm")}{" "}
                  {event.work_finish_time && format(parseISO(event.work_finish_time.split("Z")[0]), "HH:mm")}
                </div>
              </div>
            </div>
          ))}

        <FullCalendar
          schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
          plugins={[resourceTimelinePlugin]}
          timeZone="Australia/Sydney"
          initialView={["resourceTimeline"]}
          resourceAreaHeaderContent="Testers"
          resourceAreaWidth="10%"
          resources={users}
          datesSet={handleChangeDate}
          eventOverlap={false}
          events={workorders}
        />
      </Content>
    </Container>
  );
}

export default Schedule;
