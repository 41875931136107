import React, { useState } from "react";
import JWTDecode from "jwt-decode";

const AccountContext = React.createContext();
export const AccountConsumer = AccountContext;

export default function AccountProvider(props) {
  var SavedUserDetails = localStorage.getItem("access_token");
  if (SavedUserDetails) {
    SavedUserDetails = JWTDecode(SavedUserDetails);
  }

  const getPreferences = () => {
    const preferences = localStorage.getItem("preferences");
    return preferences ? JSON.parse(preferences) : {};
  };

  const [userDetails, setUserDetails] = useState({
    user_id: SavedUserDetails ? SavedUserDetails.user_id : undefined,
    username: SavedUserDetails ? SavedUserDetails.username : undefined,
    first_name: SavedUserDetails ? SavedUserDetails.first_name : undefined,
    is_field_only: SavedUserDetails ? SavedUserDetails.is_field_only : undefined,
    user_level: SavedUserDetails ? SavedUserDetails.user_level : undefined,
    sidenav_open: true,
    theme: "light", // Default theme is light
    ...getPreferences(),
    updateAccount: (updatedAccount) => updateAccount(updatedAccount),
  });

  const updateAccount = (updatedAccount) => {
    setUserDetails((prevState) => {
      const newState = {
        ...prevState,
        ...updatedAccount,
      };
      localStorage.setItem("preferences", JSON.stringify(newState));
      return newState;
    });
  };

  const updateSidenavOpen = (isOpen) => {
    setUserDetails((prev) => {
      const newState = {
        ...prev,
        sidenav_open: isOpen,
      };
      localStorage.setItem("preferences", JSON.stringify(newState));
      return newState;
    });
  };

  return <AccountContext.Provider value={{ ...userDetails, updateSidenavOpen }}>{props.children}</AccountContext.Provider>;
}
