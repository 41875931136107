import { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import API from "../../../utils/API";
import { AiOutlineDelete } from "react-icons/ai";

import CheckRoundIcon from "@rsuite/icons/CheckRound";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import { IconButton, List, Checkbox, Button, Input, FlexboxGrid, Col, Row } from "rsuite";

function ClientContactMulti(props) {
  let navigate = useNavigate();
  const is_add_mode = !props.id;
  const client_id = props.id;
  const {
    register,
    watch,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm();
  const { fields, append, remove } = useFieldArray({ name: "client_contacts", control });

  useEffect(() => {
    API.get(`/clients/${props.id}/contacts`)
      .then((r) => {
        console.log(r);
        reset({
          client_id: client_id,
          client_contacts: r.data ? r.data : undefined,
        });
      })
      .catch((e) => console.log(e));
  }, [props.id, reset, is_add_mode]);

  const onSubmit = (data) => {
    API.patch(`/clients/${props.id}/contacts`, data)
      // .then((r) => navigate(`/clients/${props.id}`))
      .catch((e) => console.log(e.response.data.errors));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="standard-form">
      <input type="hidden" {...register("client_id", { valueAsNumber: true })} />

      <List bordered>
        {fields.map((item, i) => (
          <List.Item key={i} index={i}>
            <FlexboxGrid align="middle">
              <FlexboxGrid.Item as={Col} colspan={6}>
                <Controller
                  name={`client_contacts.${i}.email`}
                  control={control}
                  render={({ field }) => <Input {...field} placeholder="Email" style={{ marginRight: "10px" }} />}
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item as={Col} colspan={6}>
                <Controller
                  name={`client_contacts.${i}.receive_field_report`}
                  control={control}
                  render={({ field }) => (
                    <Checkbox {...field} checked={field.value} onChange={(value, checked) => field.onChange(checked)} style={{ marginRight: "10px" }}>
                      Receive Field Report
                    </Checkbox>
                  )}
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item as={Col} colspan={6}>
                <Controller
                  name={`client_contacts.${i}.receive_test_report`}
                  control={control}
                  render={({ field }) => (
                    <Checkbox {...field} checked={field.value} onChange={(value, checked) => field.onChange(checked)} style={{ marginRight: "10px" }}>
                      Receive Test Report
                    </Checkbox>
                  )}
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item as={Col} colspan={6} style={{ textAlign: "right" }}>
                <input type="hidden" name={`client_contacts[${i}].id`} {...register(`client_contacts.${i}.id`)} />
                <Button appearance="subtle" onClick={() => remove(i)} style={{ marginLeft: "auto" }}>
                  <AiOutlineDelete size="1.2rem" opacity="0.2" cursor="pointer" />
                </Button>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </List.Item>
        ))}
        <List.Item>
          <Button
            appearance="primary"
            onClick={() => {
              append({ id: null, email: "", receive_field_report: true, receive_test_report: true });
            }}
          >
            Add Contact
          </Button>
        </List.Item>
      </List>

      <IconButton type="submit" appearance="primary" color="green" disabled={isSubmitting} icon={<CheckRoundIcon />}>
        Save Changes
      </IconButton>
    </form>
  );
}

export default ClientContactMulti;
