import { useEffect, useState, useCallback } from "react";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import ReportList from "../../components/ReportList";
import { Buffer } from "buffer";
import DisplayPDF from "../../components/displayPDF";
import API from "../../utils/API";
import { Container, Content, Grid, Row, Col, Panel, Button, ButtonToolbar, Stack, Message } from "rsuite";

function Reports() {
  const [reports, set_reports] = useState([]);
  const [selected_report, set_selected_report] = useState(null);
  const [approval_is_loading, set_approval_is_loading] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [error, setError] = useState(null);

  const getReports = useCallback(() => {
    API.get("/reports", { params: { page: pageNumber, filter_approved: false } })
      .then((r) => {
        r.data.data.map((report) => (report.warnings = JSON.parse(Buffer.from(report.warnings, "base64").toString())));
        set_reports(r.data.data);
        set_selected_report(r.data.data[0]);
        setNumPages(r.data.pages);
      })
      .catch((e) => setError(e.message));
  }, [pageNumber]);

  useEffect(() => {
    getReports();
  }, [getReports]);

  const approveReport = (report_id, distribute) => {
    set_approval_is_loading(true);
    API.post(`/reports/approve`, {
      report_id: Number(report_id),
      distribute,
    })
      .then((r) => {
        set_approval_is_loading(false);
        getReports();
      })
      .catch((e) => {
        set_approval_is_loading(false);
        alert("Error received from server generating report, please try again.");
      });
  };

  return (
    <Container>
      <Content>
        <Grid fluid>
          <Row>
            <Col xs={20}>
              <Panel>{selected_report && <DisplayPDF url={selected_report.file_url} />}</Panel>
            </Col>
            <Col xs={4}>
              <Panel bodyFill>
                <ReportList reports={reports} set_selected_report={set_selected_report} />
                <ButtonToolbar>
                  <Button appearance="primary" disabled={approval_is_loading} onClick={() => approveReport(selected_report.id, true)}>
                    {approval_is_loading ? (
                      <>
                        <CgSpinner className="icon-spin" size={18} /> Generating...
                      </>
                    ) : (
                      <>
                        <BsCheck2Circle size={18} />
                        Approve & Distribute
                      </>
                    )}
                  </Button>
                  <Button appearance="default" disabled={approval_is_loading} onClick={() => approveReport(selected_report.id, false)}>
                    {approval_is_loading ? (
                      <>
                        <CgSpinner className="icon-spin" size={18} /> Generating...
                      </>
                    ) : (
                      <>
                        <BsXCircle size={18} />
                        Approve Without Distributing
                      </>
                    )}
                  </Button>
                </ButtonToolbar>
              </Panel>
            </Col>
          </Row>
        </Grid>
      </Content>
    </Container>
  );
}

export default Reports;
