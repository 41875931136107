import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

const CylinderStatsChart = ({ rawData }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (rawData.length > 0) {
      const transformedData = transformData(rawData);
      setData(transformedData);
    }
  }, [rawData]);

  const transformData = (data) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const groupedData = {};

    data.forEach(({ year, month, specimen_count }) => {
      if (!groupedData[year]) {
        groupedData[year] = Array(12).fill(null);
      }
      groupedData[year][month - 1] = specimen_count;
    });

    return months.map((month, index) => {
      const entry = { month };
      Object.keys(groupedData).forEach((year) => {
        entry[year] = groupedData[year][index];
      });
      return entry;
    });
  };

  const getLineStyle = (year, minYear, maxYear) => {
    const yearRange = maxYear - minYear;
    const relativeYear = year - minYear;
    const opacity = 0.3 + (relativeYear / yearRange) * 0.7;
    const strokeWidth = 1 + (relativeYear / yearRange) * 2;
    return { stroke: `rgba(0, 0, 0, ${opacity})`, strokeWidth };
  };

  const years = Object.keys(data[0] || {}).filter((key) => key !== "month");
  const minYear = Math.min(...years.map(Number));
  const maxYear = Math.max(...years.map(Number));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={data ?? []}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
        <XAxis dataKey="month" label={{ value: "Month", position: "insideBottom", style: { fontWeight: "bold", textAlign: "center" } }} />
        <YAxis label={{ value: "Cylinder Count", angle: -90, position: "insideLeft", dy: 50, style: { fontWeight: "bold" } }} />
        <Tooltip contentStyle={{ backgroundColor: "#f5f5f5", border: "1px solid #ccc" }} />
        <Legend verticalAlign="top" height={36} />
        {years.map((year) => {
          const { stroke, strokeWidth } = getLineStyle(Number(year), minYear, maxYear);
          return <Line key={year} type="monotone" dataKey={year} stroke={stroke} strokeWidth={strokeWidth} connectNulls={false} />;
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CylinderStatsChart;
