// import Form from "./Form";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Form from "./SingleConcreteLabForm";
import { Container, Content, Panel, Heading } from "rsuite";

function SingleConcreteLab() {
  const [workorderID, setWorkorderId] = useState();
  const [concrete_sampled_id, setConcreteSampleID] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setWorkorderId(searchParams.get("wo"));
    setConcreteSampleID(searchParams.get("csid"));
  }, []);

  // let { workorderID } = searchParams.get("wo");
  return (
    <Container>
      <Content>
        <Panel>
          <Heading level={6}>Concrete Sample</Heading>
        </Panel>
        <Panel>{workorderID && <Form id={concrete_sampled_id ? concrete_sampled_id : null} workorder_id={workorderID} />}</Panel>
      </Content>
    </Container>
  );
}

export default SingleConcreteLab;
