import { useEffect, useState, useContext } from "react";
import { format, parseISO } from "date-fns";

import { Link, useNavigate, useParams } from "react-router-dom";
import ConcreteSampleListNew from "../../components/ConcreteSampleList";
import { NotificationConsumer } from "../../context/NotificationContext";

import ConcreteSampleCylinderList from "../../components/ConcreteSampleCylinderList";

import {
  Container,
  Content,
  Dropdown,
  Stack,
  Tabs,
  Divider,
  Panel,
  Badge,
  Button,
  Text,
  Heading,
  ButtonToolbar,
  ButtonGroup,
  Grid,
  Row,
  Col,
  FlexboxGrid,
  IconButton,
} from "rsuite";

import FolderFillIcon from "@rsuite/icons/FolderFill";
import SendIcon from "@rsuite/icons/Send";
import DetailIcon from "@rsuite/icons/Detail";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import ReloadIcon from "@rsuite/icons/Reload";
import TextImageIcon from "@rsuite/icons/TextImage";
import PageIcon from "@rsuite/icons/Page";
import AddOutlineIcon from "@rsuite/icons/AddOutline";

import API from "../../utils/API";

function Workorder() {
  let navigate = useNavigate();
  let { workorderID } = useParams();
  let { notify } = useContext(NotificationConsumer);

  const [workorder, set_workorder] = useState();
  const [concrete_samples, set_concrete_samples] = useState();
  const [concrete_samples_is_loading, set_concrete_samples_is_loading] = useState(true);
  const [concrete_cylinders, set_concrete_cylinders] = useState();
  const [generate_is_loading, set_generate_is_loading] = useState(false);

  useEffect(() => {
    API.get(`/workorders/${workorderID}`)
      .then((r) => {
        set_workorder(r.data);
      })
      .catch((e) => console.log(e));
    set_concrete_samples_is_loading(true);
    API.get("/concrete_samples", {
      params: { page: 0, limit: 100, workorder_id: workorderID },
    })
      .then((r) => {
        set_concrete_samples(r.data.data);
        set_concrete_cylinders(
          r.data.data
            ?.filter((sample) => sample.cylinder_specimens !== undefined)
            .flatMap((item) => item.cylinder_specimens)
            .filter((a) => a)
            .sort((a, b) => a.id - b.id)
            .sort((a, b) => a.days_requested - b.days_requested)
        );
        set_concrete_samples_is_loading(false);
      })
      .catch((e) => console.log(e));
  }, [workorderID]);

  const generateReport = (workorder_id, report_template, issue_number, issue_date, issue_should_distribute, nata, auto_approve, save_file_prefix, signed_by, comments) => {
    set_generate_is_loading(true);
    API.post(`/reports/generate`, {
      workorder_id: Number(workorder_id),
      report_template,
      issue_number,
      issue_date,
      issue_should_distribute: false,
      nata,
      auto_approve,
      save_file_prefix,
      signed_by,
      comments,
    })
      .then((r) => {
        set_generate_is_loading(false);
        notify("Successfully generated report", "success");
        // navigate(`/reports`);
      })
      .catch((e) => {
        set_generate_is_loading(false);
        notify("Could not generate report", "error");
      });
  };

  const has_previous = concrete_samples && concrete_samples.reduce((prev, current) => (+prev.id > +current.id ? prev : current)).id;
  return workorder ? (
    <Container>
      <Content>
        <Panel>
          <Stack justifyContent="space-between">
            <Stack.Item>
              <Heading level={5}>
                #{workorderID} - {workorder.project ? <span>{workorder.project.name}</span> : <span>{workorder.field_customer}</span>}
              </Heading>

              <Text muted>
                {workorder.work_date && format(parseISO(workorder.work_date), "dd MMM y")} |{" "}
                {workorder.project ? (
                  workorder.project &&
                  workorder.project.locations && (
                    <span style={{ textTransform: "uppercase" }}>
                      {workorder.project.locations[0].address_line_1},{workorder.project.locations[0].address_line_2}
                    </span>
                  )
                ) : (
                  <span style={{ textTransform: "uppercase" }}>
                    {workorder.field_address_line_1} {workorder.field_address_line_2}
                  </span>
                )}
              </Text>
            </Stack.Item>

            <Stack.Item>
              <Dropdown title="Actions" size="sm" appearance="subtle">
                <Dropdown.Item onClick={() => navigate("update")}>Edit</Dropdown.Item>
                <Dropdown.Separator />
                <Dropdown.Item onClick={() => navigate("delete")}>Delete</Dropdown.Item>
              </Dropdown>
            </Stack.Item>
          </Stack>
          <Divider />
          <Grid fluid>
            <Row>
              <Col xs={24}>
                <ButtonToolbar style={{ justifyContent: "flex-end" }}>
                  <Dropdown style={{ minWidth: "100px" }} title="Reports" placement="bottomEnd" icon={<PageIcon />} loading={generate_is_loading}>
                    {workorder.reports &&
                      workorder.reports.map((report) => (
                        <a href={report.file_url}>
                          {report.report_template === "standard_concrete_test_report" && <Dropdown.Item icon={<FileDownloadIcon />}>Test Report</Dropdown.Item>}
                          {report.report_template === "standard_concrete_test_field_sheet" && <Dropdown.Item icon={<FileDownloadIcon />}>Field Sheet</Dropdown.Item>}
                        </a>
                      ))}

                    <Dropdown.Item
                      icon={<ReloadIcon />}
                      onClick={() => generateReport(workorderID, "standard_concrete_test_report", 1, null, true, true, false, "CON-COMP", 1, null)}
                    >
                      Generate Test Report
                    </Dropdown.Item>
                    <Dropdown.Item
                      icon={<ReloadIcon />}
                      onClick={() => generateReport(workorderID, "standard_concrete_test_field_sheet", 1, null, false, false, true, "FTS", 1, null)}
                    >
                      Generate Field Sheet
                    </Dropdown.Item>
                    <Dropdown.Item disabled icon={<SendIcon />}>
                      Email Test Report
                    </Dropdown.Item>
                  </Dropdown>
                  {has_previous ? (
                    <IconButton appearance="primary" icon={<AddOutlineIcon />} onClick={() => navigate(`/tests/concrete/single_lab/new?wo=${workorderID}&cp=${has_previous}`)}>
                      Concrete Sample
                    </IconButton>
                  ) : (
                    <IconButton appearance="primary" icon={<AddOutlineIcon />} onClick={() => navigate(`/tests/concrete/single_lab/new?wo=${workorderID}`)}>
                      Concrete Sample
                    </IconButton>
                  )}
                </ButtonToolbar>
              </Col>
            </Row>
            <Row style={{ marginTop: "1rem" }}>
              <Col xs={24} style={{ padding: 0 }}>
                <ConcreteSampleListNew is_loading={concrete_samples_is_loading} concrete_samples={concrete_samples} />
              </Col>
            </Row>
            <Row style={{ marginTop: "1rem", marginBottom: "5rem" }}>
              <Col xs={24} style={{ padding: 0 }}>
                {<ConcreteSampleCylinderList is_loading={concrete_samples_is_loading} concrete_cylinders={concrete_cylinders} />}
              </Col>
            </Row>
          </Grid>
        </Panel>
      </Content>
    </Container>
  ) : (
    "loading..."
  );
}

export default Workorder;
