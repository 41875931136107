import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

import API from "../../utils/API";

import { DateRangePicker, Stack, Panel, IconButton, Button, ButtonToolbar, ButtonGroup, Heading, Text, SelectPicker } from "rsuite";
import CylinderStatsChart from "./CylinderStatsChart"; // Import the new component

import { Container, Content } from "rsuite";

function Dashboard() {
  let navigate = useNavigate();
  const [is_loading, set_is_loading] = useState(true);
  const [cylinder_stats, set_cylinder_stats] = useState([]);
  const [cylinder_stats_by_cust, set_cylinder_stats_by_cust] = useState([]);

  const getCylinderStats = useCallback(() => {
    API.get(`/dashboard/cylinder_stats`)
      .then((r) => {
        const data = r.data; // Assuming r.data contains the JSON data
        set_cylinder_stats(data);
      })
      .catch((e) => console.log(e));
  }, []);

  // const getCylinderStatsByCust = useCallback(() => {
  //   API.get(`/dashboard/cylinder_stats_by_cust`)
  //     .then((r) => {
  //       const data = r.data;
  //       set_cylinder_stats_by_cust(data);
  //     })
  //     .catch((e) => console.log(e));
  // }, []);

  useEffect(() => {
    getCylinderStats();
    // getCylinderStatsByCust();
  }, [getCylinderStats]);

  const transformData = (data) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const transformed = {};

    data.forEach((item) => {
      const month = months[item.month - 1];
      if (!transformed[month]) {
        transformed[month] = { month };
      }
      transformed[month][item.billing_customer_name] = item.specimen_count;
    });

    return Object.values(transformed);
  };

  return (
    <Container>
      <Content>
        <Panel>
          <Stack alignItems="center" justifyContent="space-between">
            <Stack.Item>
              <Heading level={6}>Dashboard</Heading>
            </Stack.Item>
          </Stack>
        </Panel>
        {cylinder_stats && <CylinderStatsChart rawData={cylinder_stats} />}
      </Content>
    </Container>
  );
}

export default Dashboard;
