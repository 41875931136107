import { useCallback, useEffect, useState } from "react";
import { Input, InputGroup, Container, Content,  Stack, Panel, IconButton, Button, ButtonToolbar, ButtonGroup, Heading, Text } from "rsuite";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import SearchIcon from '@rsuite/icons/Search';

import ProjectList from "../../components/ProjectList";
import API from "../../utils/API";
import { useNavigate } from "react-router-dom";

function Projects() {
  let navigate = useNavigate();

  const [is_loading, set_is_loading] = useState(true)
  const [projects, set_projects] = useState();
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(0);


  const GetProjects = useCallback(() => {
    set_is_loading(true)
    API.get("/projects", { params: { page: pageNumber, limit: 20 } })
      .then((r) => {
        set_projects(r.data.data);
        setNumPages(r.data.pages);
        set_is_loading(false)
      })
      .catch((e) => console.log(e));
  }, [pageNumber]);

  useEffect(() => {
    GetProjects();
  }, [GetProjects]);


  const search_for_project = (s_string) => {
    if (s_string) {
      set_is_loading(true)
      API.get(`/projects/search/${s_string}`)
        .then((r) => {
          set_projects(r.data.data);
          setNumPages(r.data.pages);
          set_is_loading(false)
        })
        .catch((e) => console.log(e));
    } else {
      GetProjects();
    }
  };

  return (
    <Container>
      <Content>
        <Panel>
          <Stack
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack.Item>
              <Heading level={6}>Projects</Heading>
            </Stack.Item>

            <Stack>

              <Text style={{marginRight:'10px'}} muted>Page {pageNumber} of {numPages}</Text>

              <Stack style={{marginRight:'10px'}}>
              <InputGroup inside >
                  <InputGroup.Addon>
                    <SearchIcon />
                  </InputGroup.Addon>
                  <Input placeholder="Search for projects" onChange={search_for_project} />
                </InputGroup>
              </Stack>

              <ButtonToolbar>
                <ButtonGroup>
                  <Button disabled={is_loading || pageNumber=== 0} onClick={pageNumber=== 0 ? undefined : () => setPageNumber(pageNumber - 1)}>
                    Prev
                  </Button>
                  <Button disabled={is_loading || pageNumber=== numPages} onClick={pageNumber=== numPages ? undefined : () => setPageNumber(pageNumber + 1)}>
                    Next
                  </Button>
                </ButtonGroup>
                <ButtonToolbar>
                  <IconButton appearance="primary" color="green" onClick={() => navigate("new")} icon={<AddOutlineIcon />}>
                    Create
                  </IconButton>
                </ButtonToolbar>

              </ButtonToolbar>

            </Stack>
          </Stack>
        </Panel>

        <Panel>
          <ProjectList projects={projects} is_loading={is_loading} />
        </Panel>
      </Content>
    </Container>
  );
}

export default Projects;
