import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Badge, Button, ButtonToolbar, Container, Content, Dropdown, Heading, IconButton, Panel, Stack, Text, Divider } from "rsuite";
import WorkorderList from "../../components/WorkorderListNew";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import API from "../../utils/API";

function Project() {
  let navigate = useNavigate();
  let { projectID } = useParams();
  const [project, set_project] = useState();
  const [workorders, set_workorders] = useState();

  // const [PWnumPages, setPWNumPages] = useState();
  const [PWpageNumber, setPWPageNumber] = useState(0);

  useEffect(() => {
    API.get(`/projects/${projectID}`)
      .then((r) => {
        set_project(r.data);
      })
      .catch((e) => console.log(e));

    const getProjectWorkorders = () => {
      API.get(`/projects/${projectID}/workorders`, { params: { page: PWpageNumber, limit: 25 } })
        .then((r) => {
          set_workorders(r.data.data);
          setPWPageNumber(r.data.pages);
        })
        .catch((e) => console.log(e));
    };
    getProjectWorkorders();
  }, [PWpageNumber, projectID]);

  return project ? (
    <Container>
      <Content>
        <Panel>
          <Stack justifyContent="space-between">
            <Stack.Item>
              <Heading level={5}>
                {project.name} (#{projectID})
              </Heading>

              <Text muted>
                {project.locations[0].address_line_1} {project.locations[0].address_line_2}
              </Text>
            </Stack.Item>

            <Stack.Item>
              <Dropdown title="Actions" size="sm" appearance="subtle">
                <Dropdown.Item onClick={() => navigate("update")}>Edit</Dropdown.Item>
                <Dropdown.Separator />
                <Dropdown.Item onClick={() => navigate("delete")}>Delete</Dropdown.Item>
              </Dropdown>
            </Stack.Item>
          </Stack>
          <Divider />

          <Stack justifyContent="flex-end">
            <ButtonToolbar>
              <Button appearance="default" onClick={() => navigate(`contacts`)}>
                {project.contacts && <Badge content={project.contacts.length} style={{ marginRight: "5px", backgroundColor: "gray" }} />}Contacts
              </Button>
              <IconButton appearance="primary" icon={<AddOutlineIcon />} onClick={() => navigate(`/workorders/new?p=${project.id}`)}>
                Add Workorder
              </IconButton>
            </ButtonToolbar>
          </Stack>

          <div style={{ marginTop: "1rem" }}>
            <WorkorderList workorders={workorders} showProjectInfo={false} />
          </div>
        </Panel>
      </Content>
    </Container>
  ) : (
    "loading..."
  );
}

export default Project;
