import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import { Form, IconButton, Grid, Row, Col, Text, Input, SelectPicker } from "rsuite";

import API from "../../utils/API";
import { format, parseISO, subDays } from "date-fns";

function FormProject(props) {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [sp_project_id, setSPProjectID] = useState();

  const is_add_mode = !props.id;
  const [clients, set_clients] = useState();
  // const [projects, set_projects] = useState();

  const {
    register,
    watch,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  const filterProjects = (inputValue) =>
    API.get(`/projects/search/${inputValue}`).then(
      (r) =>
        (r.data.data = r.data.data.map(({ id: value, name: label, locations, status }) => ({
          value,
          label,
          locations,
          status,
        })))
    );

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(filterProjects(inputValue));
    });

  function formatOptionLabel({ label, locations, status }, { context, inputValue }) {
    return context === "menu" ? (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div>
          <div style={{ fontWeight: "bold" }}>{label}</div>
          <div style={{ color: "black", textTransform: "uppercase", fontFamily: "monospace" }}>
            {locations && locations[0].address_line_1} {locations && locations[0].address_line_2}
          </div>
        </div>
        <div>
          {status === 1 && <span className="active">Active</span>}
          {status === 2 && <span className="inactive">Inactive</span>}
        </div>
      </div>
    ) : (
      <div>
        <div>{label}</div>
      </div>
    );
  }

  useEffect(() => {
    setSPProjectID(searchParams.get("p"));

    API.get(`/clients`, { params: { limit: 200 } })
      .then((r) => {
        set_clients((r.data.data = r.data.data.map(({ id: value, name: label }) => ({ value, label }))));
      })
      .catch((e) => console.log(e));

    // API.get(`/projects`, { params: { limit: 5 } })
    //   .then((r) => {
    //     set_projects((r.data.data = r.data.data.map(({ id: value, name: label }) => ({ value, label }))));
    //   })
    //   .catch((e) => console.log(e));

    if (!is_add_mode) {
      API.get(`/workorders/${props.id}`)
        .then((r) => {
          reset({
            id: r.data.id,
            work_date: r.data.work_date && format(parseISO(r.data.work_date), "Y-MM-dd"),
            work_start_time: r.data.work_start_time && format(parseISO(r.data.work_start_time.split("Z")[0]), "HH:mm"),
            work_finish: r.data.work_finish && format(parseISO(r.data.work_finish), "Y-MM-dd"),
            work_finish_time: r.data.work_finish_time && format(parseISO(r.data.work_finish_time.split("Z")[0]), "HH:mm"),
            billing_customer: r.data.billing_customer && r.data.billing_customer.id,
            billing_customer_ref: r.data.billing_customer_ref && r.data.billing_customer_ref,
            project: r.data.project && { value: r.data.project.id, label: r.data.project.name },
            field_address_line_1: r.data.field_address_line_1 && r.data.field_address_line_1,
            field_address_line_2: r.data.field_address_line_2 && r.data.field_address_line_2,
            field_customer: r.data.field_customer && r.data.field_customer,
            field_instructions: r.data.field_instructions && r.data.field_instructions,
            // field_assigned: r.data.field_assigned && r.data.field_assigned,
            // field_planned: r.data.field_planned && r.data.field_planned,
            field_complete: r.data.field_complete && r.data.field_complete,
          });
        })
        .catch((e) => console.log(e));
    } else {
      // If sp_project_id is set we have a project specified to use in the url search params
      if (sp_project_id) {
        API.get(`/projects/${sp_project_id}`)
          .then((r) => {
            reset({
              work_date: format(subDays(new Date(), 1), "Y-MM-dd"),
              work_finish: format(subDays(new Date(), 1), "Y-MM-dd"),
              project: { value: r.data.id, label: r.data.name },
            });
          })
          .catch((e) => console.log(e));
      } else {
        reset({
          work_date: format(subDays(new Date(), 1), "Y-MM-dd"),
          work_finish: format(subDays(new Date(), 1), "Y-MM-dd"),
        });
      }
    }
  }, [props.id, reset, is_add_mode, sp_project_id, searchParams]);

  const onSubmit = (data) => {
    if (data.project && data.project.value) {
      data.project = data.project.value;
    }
    if (data.work_date === "") {
      data.work_date = null;
    }
    if (data.work_start_time === "") {
      data.work_start_time = null;
    }
    if (data.work_finish === "") {
      data.work_finish = null;
    }
    if (data.work_finish_time === "") {
      data.work_finish_time = null;
    }
    if (!is_add_mode) {
      API.patch(`/workorders/${props.id}`, data)
        .then((r) => navigate(`/workorders/${props.id}`))
        .catch((e) => console.log(e.response.data.errors));
    } else {
      API.post("/workorders", data)
        .then((r) => navigate(`/workorders`))
        .catch((e) => console.log(e.response.data.errors));
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid style={{ padding: 0, margin: 0 }}>
        <input type="hidden" {...register("id", { valueAsNumber: true })} />

        <Row>
          <Col xs={12}>
            <Text htmlFor="work_date" className="bold">
              Work Date
            </Text>
            <input {...register("work_date")} type="date" min="2000-01-01" max="2050-01-01" />
            {errors.work_date && <span>This field is required</span>}
          </Col>
          <Col xs={4}>
            <Text htmlFor="work_start_time" className="bold">
              Start Time
            </Text>
            <input {...register("work_start_time")} type="time" />
            {errors.work_date && <span>This field is required</span>}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Text htmlFor="work_finish" className="bold">
              Work Finished
            </Text>
            <input {...register("work_finish")} type="date" min="2000-01-01" max="2050-01-01" />
            {errors.work_finish && <span>This field is required</span>}
          </Col>
          <Col xs={4}>
            <Text htmlFor="work_finish_time" className="bold">
              End Time
            </Text>
            <input {...register("work_finish_time")} type="time" />
            {errors.work_date && <span>This field is required</span>}
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Text>Project</Text>
            <Controller
              name="project"
              control={control}
              render={({ field }) => (
                <AsyncSelect
                  {...field}
                  styles={customStyles}
                  isClearable
                  cacheOptions
                  loadOptions={promiseOptions}
                  formatOptionLabel={formatOptionLabel}
                  // formatOptionLabel={formatOptionLabel}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Text>Billing customer</Text>
            <Controller
              name="billing_customer"
              // rules={{ required: true }}
              control={control}
              render={({ field: { value, onChange } }) => (
                <ReactSelect
                  isDisabled={watch("project")}
                  styles={customStyles}
                  options={clients}
                  value={clients && clients.find((c) => c.value === value)}
                  onChange={(val) => onChange(val ? val.value : null)}
                  isClearable="true"
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Text htmlFor="billing_customer_ref" className="bold">
              Cust Ref#
            </Text>
            <input {...register("billing_customer_ref")} />
            {errors.billing_customer_ref && <span>This field is required</span>}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Text htmlFor="field_address_line_1" className="bold">
              Street Address
            </Text>
            <input {...register("field_address_line_1")} disabled={watch("project")} />
            {errors.field_address_line_1 && <span>This field is required</span>}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Text htmlFor="field_address_line_2" className="bold">
              Suburb
            </Text>
            <input {...register("field_address_line_2")} disabled={watch("project")} />
            {errors.field_address_line_2 && <span>This field is required</span>}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Text htmlFor="field_customer" className="bold">
              Customer
            </Text>
            <input {...register("field_customer")} disabled={watch("project")} />
            {errors.field_customer && <span>This field is required</span>}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Text htmlFor="field_instructions" className="bold">
              Instructions
            </Text>
            <input {...register("field_instructions")} />
            {errors.field_instructions && <span>This field is required</span>}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Text htmlFor="field_complete" className="bold">
              Field Complete
            </Text>
            <Controller name="field_complete" control={control} render={({ field }) => <input type="checkbox" {...field} checked={field.value} />} />
            {errors.field_complete && <span>This field is required</span>}
          </Col>
        </Row>
        <br />
        <IconButton type="submit" appearance="primary" color="green" disabled={isSubmitting} icon={is_add_mode ? <AddOutlineIcon /> : <CheckRoundIcon />}>
          {is_add_mode ? "Add Workorder" : "Save Changes"}
        </IconButton>
      </Grid>
    </Form>
  );
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "1rem",
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "1px",
    borderColor: "rgb(118, 118, 118);",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: "0",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: "0",
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: "0",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};

export default FormProject;
