import Form from "./Form";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Content } from "rsuite";
import API from "../../utils/API";

function WorkorderDelete() {
  let navigate = useNavigate();
  let { workorderID } = useParams();

  const delWorkorder = () => {
    API.delete(`/workorders/${workorderID}`)
      .then((r) => {
        navigate(`/workorders`);
      })
      .catch((e) => alert("Error received from server"));
  };
  return (
    <Container>
      <Content>
        <div className="page-header">
          <div className="page-header-container">
            <h1>Delete workorder</h1>
          </div>
        </div>

        <div className="container" style={{ maxWidth: "800px" }}>
          <h2>Are you sure you want to delete this workorder?</h2>
          <button className="r-info-CRITICAL" onClick={delWorkorder}>
            Delete this workorder permanently!
          </button>
        </div>
      </Content>
    </Container>
  );
}

export default WorkorderDelete;
