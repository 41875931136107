import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import WorkorderListNew from "../../components/WorkorderListNew";
import API from "../../utils/API";

import { format, startOfDay, endOfDay, subDays, addDays } from "date-fns";
import { DateRangePicker, Stack, Panel, IconButton, Button, ButtonToolbar, ButtonGroup, Heading, Text, SelectPicker } from "rsuite";
import AddOutlineIcon from "@rsuite/icons/AddOutline";

import { Container, Content } from "rsuite";

function Workorders() {
  let navigate = useNavigate();
  const [is_loading, set_is_loading] = useState(true);
  const [workorders, set_workorders] = useState();
  const [clients, set_clients] = useState();
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(0);

  // Filters
  const [date_range_from, set_date_range_from] = useState(undefined);
  const [date_range_too, set_date_range_too] = useState(undefined);
  const [filter_customer, set_filter_customer] = useState(undefined);

  const getClients = useCallback(() => {
    API.get(`/clients`, { params: { limit: 200 } })
      .then((r) => {
        set_clients((r.data.data = r.data.data.map(({ id: value, name: label }) => ({ value, label }))));
      })
      .catch((e) => console.log(e));
  }, []);

  const getWorkorders = useCallback(() => {
    set_is_loading(true);
    API.get("/workorders", {
      params: {
        page: pageNumber,
        limit: 20,
        from: date_range_from,
        too: date_range_too,
        customer: filter_customer,
      },
    })
      .then((r) => {
        set_workorders(r.data.data);
        setNumPages(r.data.pages);
        set_is_loading(false);
      })
      .catch((e) => console.log(e));
  }, [pageNumber, date_range_from, date_range_too, filter_customer]);

  useEffect(() => {
    getClients();
    getWorkorders();
  }, [getWorkorders, getClients]);

  const handleFilterCustomer = (values) => {
    if (values) {
      set_filter_customer(values);
    } else {
      set_filter_customer(undefined);
    }
  };

  const handleDateChange = (values) => {
    if (values && values[0] && values[1]) {
      set_date_range_from(format(values[0], "y-MM-dd"));
      set_date_range_too(format(values[1], "y-MM-dd"));
    } else {
      set_date_range_from(undefined);
      set_date_range_too(undefined);
    }
    // getWorkorders()
  };

  const Ranges = [
    {
      label: "Tomorrow",
      value: [startOfDay(addDays(new Date(), 1)), endOfDay(addDays(new Date(), 1))],
    },
    {
      label: "Today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: "Yesterday",
      value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))],
    },
    {
      label: "last7Days",
      value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
    },
  ];

  return (
    <Container>
      <Content>
        <Panel>
          <Stack alignItems="center" justifyContent="space-between">
            <Stack.Item>
              <Heading level={6}>Workorders</Heading>
            </Stack.Item>

            <Stack.Item>
              <ButtonToolbar>
                <Text muted>
                  Page {pageNumber} of {numPages}
                </Text>
                <DateRangePicker
                  style={{ width: 180 }}
                  disabled={is_loading}
                  character=" → "
                  placeholder="Select Date Range"
                  ranges={Ranges}
                  format="dd-MM-yyyy"
                  showOneCalendar
                  onChange={handleDateChange}
                />
                <SelectPicker style={{ width: 150 }} disabled={is_loading} loading={!clients} label="Bill to" data={clients ? clients : []} onChange={handleFilterCustomer} />
                <ButtonGroup>
                  <Button disabled={is_loading || pageNumber === 0} onClick={pageNumber === 0 ? undefined : () => setPageNumber(pageNumber - 1)}>
                    Prev
                  </Button>
                  <Button disabled={is_loading || pageNumber === numPages} onClick={pageNumber === numPages ? undefined : () => setPageNumber(pageNumber + 1)}>
                    Next
                  </Button>
                </ButtonGroup>
                <ButtonToolbar>
                  <IconButton appearance="primary" color="green" onClick={() => navigate("new")} icon={<AddOutlineIcon />}>
                    Create
                  </IconButton>
                </ButtonToolbar>
              </ButtonToolbar>
            </Stack.Item>
          </Stack>
        </Panel>

        <Panel>
          <WorkorderListNew workorders={workorders} is_loading={is_loading} />
        </Panel>
      </Content>
    </Container>
  );
}

export default Workorders;
