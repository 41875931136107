import React from "react";
import { List, Panel, FlexboxGrid, Message, Highlight, Text } from "rsuite";
import { format, parseISO } from "date-fns";

const getUniqueWarnings = (warnings) => {
  const uniqueWarnings = new Set();
  return warnings.filter((w) => {
    const warningKey = `${w.type}-${w.message}`;
    if (uniqueWarnings.has(warningKey)) {
      return false;
    } else {
      uniqueWarnings.add(warningKey);
      return true;
    }
  });
};

function ReportList(props) {
  const { reports, set_selected_report } = props;

  return (
    <Panel bordered header="Reports to Approve">
      <List hover>
        {reports.map((report, index) => (
          <List.Item key={index} index={index} onClick={() => set_selected_report(report)} size="sm">
            <FlexboxGrid>
              <FlexboxGrid.Item colspan={24}>
                <Text weight="medium">{`${report.save_file_prefix}-${report.workorder_id}`}</Text>

                {report.warnings && (
                  <Message type="error">
                    <ol>
                      {getUniqueWarnings(report.warnings).map((w, i) => (
                        <div key={i}>{w.message}</div>
                      ))}
                    </ol>
                  </Message>
                )}
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </List.Item>
        ))}
      </List>
    </Panel>
  );
}

export default ReportList;
