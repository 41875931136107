import { formatDistanceToNow } from "date-fns";
import { Link } from "react-router-dom";
import { Table, Text } from "rsuite";

function ProjectList(props) {
  const { projects, is_loading } = props;
  const { Column, HeaderCell, Cell } = Table;
  const data = projects;

  return (
    <Table
      loading={is_loading}
      height={300}
      hover={true}
      // fillHeight={true}
      showHeader={true}
      autoHeight={true}
      data={data ? data : []}
      bordered={true}
      cellBordered={true}
      headerHeight={true ? 30 : 40}
      rowHeight={true ? 30 : 46}
      style={{ fontSize: "0.8rem" }}
    >
      <Column width={60}>
        <HeaderCell style={{ padding: 4 }}>ID</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="id" />
      </Column>

      <Column width={300}>
        <HeaderCell style={{ padding: 4 }}>Name</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="name">
          {(rowData, rowIndex) => {
            return rowData.name;
          }}
        </Cell>
      </Column>

      <Column width={300}>
        <HeaderCell style={{ padding: 4 }}>Location</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="">
          {(rowData, rowIndex) => {
            return rowData.locations && rowData.locations[0].address_line_1 + " " + rowData.locations[0].address_line_2;
          }}
        </Cell>
      </Column>

      <Column flexGrow={1}>
        <HeaderCell style={{ padding: 4 }}>Bill to</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="project">
          {(rowData, rowIndex) => {
            return rowData.project ? rowData.project.billing_customer.name : rowData.billing_customer ? rowData.billing_customer.name : null;
          }}
        </Cell>
      </Column>

      <Column>
        <HeaderCell style={{ padding: 4 }}>Status</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="status">
          {(rowData, rowIndex) => {
            return (rowData.status = 1 ? "Active" : "Inactive");
          }}
        </Cell>
      </Column>

      <Column>
        <HeaderCell style={{ padding: 4 }}>Last</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="last_workorder">
          {(rowData, rowIndex) => {
            return rowData.last_workorder && <Text muted>{formatDistanceToNow(rowData.last_workorder) + " ago"}</Text>;
          }}
        </Cell>
      </Column>

      <Column width={100}>
        <HeaderCell style={{ padding: 4 }}>...</HeaderCell>
        <Cell style={{ padding: 4 }}>
          {(rowData, rowIndex) => {
            return <Link to={`/projects/${rowData.id}`}>View</Link>;
          }}
        </Cell>
      </Column>
    </Table>
  );
}

export default ProjectList;
