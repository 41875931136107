import { useCallback, useEffect, useState } from "react";
import { Input, InputGroup, Container, Content, Stack, Panel, IconButton, Button, ButtonToolbar, ButtonGroup, Heading, Text, Table, Drawer, List, HStack } from "rsuite";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import SearchIcon from "@rsuite/icons/Search";

import ProjectList from "../../components/ProjectList";
import API from "../../utils/API";
import { Link, useNavigate } from "react-router-dom";

function PriceLists() {
  let navigate = useNavigate();
  const { Column, HeaderCell, Cell } = Table;

  const [open, setOpen] = useState(false);
  const [selected_list, setSelectedList] = useState(undefined);

  const [is_loading, set_is_loading] = useState(true);
  const [projects, set_projects] = useState();
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(0);

  const GetPriceLists = useCallback(() => {
    set_is_loading(true);
    API.get("/price_lists", { params: { page: pageNumber, limit: 20 } })
      .then((r) => {
        set_projects(r.data.data);
        setNumPages(r.data.pages);
        set_is_loading(false);
      })
      .catch((e) => console.log(e));
  }, [pageNumber]);

  useEffect(() => {
    GetPriceLists();
  }, [GetPriceLists]);

  return (
    <Container>
      <Drawer open={selected_list ? true : false} onClose={() => setSelectedList(undefined)}>
        <Drawer.Header>
          <Drawer.Title>{selected_list?.name}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <List size="sm">
            {selected_list?.items?.map((pli) => (
              <List.Item key={pli.id}>
                <HStack justifyContent="space-between">
                  <Stack.Item>{pli.product_name}</Stack.Item>
                  <Stack.Item>${pli.unit_price}</Stack.Item>
                </HStack>
              </List.Item>
            ))}
          </List>
        </Drawer.Body>
      </Drawer>

      <Content>
        <Panel>
          <Stack alignItems="center" justifyContent="space-between">
            <Stack.Item>
              <Heading level={6}>Price Lists</Heading>
            </Stack.Item>

            <Stack>
              <Text style={{ marginRight: "10px" }} muted>
                Page {pageNumber} of {numPages}
              </Text>

              <ButtonToolbar>
                <ButtonGroup>
                  <Button disabled={is_loading || pageNumber === 0} onClick={pageNumber === 0 ? undefined : () => setPageNumber(pageNumber - 1)}>
                    Prev
                  </Button>
                  <Button disabled={is_loading || pageNumber === numPages} onClick={pageNumber === numPages ? undefined : () => setPageNumber(pageNumber + 1)}>
                    Next
                  </Button>
                </ButtonGroup>
                <ButtonToolbar>
                  <IconButton appearance="primary" color="green" onClick={() => navigate("new")} icon={<AddOutlineIcon />}>
                    Create
                  </IconButton>
                </ButtonToolbar>
              </ButtonToolbar>
            </Stack>
          </Stack>
        </Panel>

        <Panel>
          <Table
            loading={is_loading}
            height={300}
            hover={true}
            showHeader={true}
            autoHeight={true}
            data={projects ? projects : []}
            bordered={true}
            cellBordered={true}
            headerHeight={true ? 30 : 40}
            rowHeight={true ? 30 : 46}
            style={{ fontSize: "0.8rem" }}
          >
            <Column width={60}>
              <HeaderCell style={{ padding: 4 }}>ID</HeaderCell>
              <Cell style={{ padding: 4 }} dataKey="id" />
            </Column>

            <Column width={300}>
              <HeaderCell style={{ padding: 4 }}>Name</HeaderCell>
              <Cell style={{ padding: 4 }} dataKey="name">
                {(rowData, rowIndex) => {
                  return rowData.name;
                }}
              </Cell>
            </Column>

            <Column>
              <HeaderCell style={{ padding: 4 }}>Status</HeaderCell>
              <Cell style={{ padding: 4 }} dataKey="status">
                {(rowData, rowIndex) => {
                  return (rowData.status = 1 ? "Active" : "Inactive");
                }}
              </Cell>
            </Column>

            <Column width={100}>
              <HeaderCell style={{ padding: 4 }}>...</HeaderCell>
              <Cell style={{ padding: 4 }}>
                {(rowData, rowIndex) => {
                  return <a onClick={(e) => setSelectedList(rowData)}>View</a>;
                }}
              </Cell>
            </Column>
          </Table>
        </Panel>
      </Content>
    </Container>
  );
}

export default PriceLists;
