import { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import ClientList from "../../components/ClientList";
import { Container, Content } from "rsuite";
import { DateRangePicker, Stack, Panel, IconButton, Button, ButtonToolbar, ButtonGroup, Heading, Text, SelectPicker } from "rsuite";
import AddOutlineIcon from "@rsuite/icons/AddOutline";

import API from "../../utils/API";

function Companys() {
  let navigate = useNavigate();

  const [is_loading, set_is_loading] = useState(true);
  const [clients, set_clients] = useState();
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(0);

  const getClients = useCallback(() => {
    set_is_loading(true);
    API.get("/clients", {
      params: {
        page: pageNumber,
        limit: 20,
      },
    })
      .then((r) => {
        set_clients(r.data.data);
        setNumPages(r.data.pages);
        set_is_loading(false);
      })
      .catch((e) => console.log(e));
  }, [pageNumber]);

  useEffect(() => {
    getClients();
  }, [getClients]);

  return (
    <Container>
      <Content>
        <Panel>
          <Stack alignItems="center" justifyContent="space-between">
            <Stack.Item>
              <Heading level={6}>Clients</Heading>
            </Stack.Item>

            <Stack.Item>
              <ButtonToolbar>
                <Text muted>
                  Page {pageNumber} of {numPages}
                </Text>
                <ButtonGroup>
                  <Button disabled={is_loading || pageNumber === 0} onClick={pageNumber === 0 ? undefined : () => setPageNumber(pageNumber - 1)}>
                    Prev
                  </Button>
                  <Button disabled={is_loading || pageNumber === numPages} onClick={pageNumber === numPages ? undefined : () => setPageNumber(pageNumber + 1)}>
                    Next
                  </Button>
                </ButtonGroup>
                <ButtonToolbar>
                  <IconButton appearance="primary" color="green" onClick={() => navigate("new")} icon={<AddOutlineIcon />}>
                    Create
                  </IconButton>
                </ButtonToolbar>
              </ButtonToolbar>
            </Stack.Item>
          </Stack>
        </Panel>

        <Panel>
          <ClientList clients={clients} is_loading={is_loading} />
        </Panel>
      </Content>
    </Container>
  );
}

export default Companys;
