import { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useWindowWidth } from "@wojtekmaj/react-hooks";

function DisplayPDF(props) {
  const width = useWindowWidth();
  const { url } = props;
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    url && (
      <div>
        <div className="pdf-controls">
          <div className="controls-right">
            <span>
              Page {pageNumber} of {numPages}
            </span>
            <button disabled={pageNumber === 1 && true} onClick={() => setPageNumber(pageNumber - 1)}>
              ❮
            </button>
            <button disabled={pageNumber === numPages && true} onClick={() => setPageNumber(pageNumber + 1)}>
              ❯
            </button>
          </div>
        </div>
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} width={Math.min(width * 0.85, 1280)} />
        </Document>
      </div>
    )
  );
}

export default DisplayPDF;
