import Form from "./Form";
import { useParams } from "react-router-dom";
import { Container, Content, Panel, Heading } from "rsuite";

function ClientForm() {
  let { clientID } = useParams();
  return (
    <Container>
      <Content>
        <Panel>
          <Heading level={6}>{clientID ? "Update client" : "Add client"}</Heading>
        </Panel>

        <Panel>
          <Form id={clientID} />
        </Panel>
      </Content>
    </Container>
  );
}

export default ClientForm;
